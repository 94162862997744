<template>
  <v-form
    @submit.prevent="onSubmit"
    ref="form"
    lazy-validation
    v-model="valid"
    :readonly="readonly"
    v-if="dataLoaded"
  >
    <v-container>
      <v-container>
        <v-row>
          <v-col>
            <h1>Erfassung von Wassernutzungen im Bereich des Erftverbandes</h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2>Öffentliche Wasserversorgung</h2>
          </v-col>
          <v-col
            v-if="mode !== 'first' && status !== 'ausstehend'"
            v-show="!printing"
          >
            <FirstSubBtn
              formName="form_oeff"
              :username="user"
              :year="year"
            ></FirstSubBtn>
          </v-col>
        </v-row>
      </v-container>
      <FormLegend
        :saveData="saveData"
        :lg="lg"
        :year="year"
        :readonly="readonly"
      />

      <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
        <v-row>
          <v-col cols="12">
            <h3>Eigengewinnung</h3>
            <span>Grundwasser</span>
          </v-col>
        </v-row>
        <FormRowMulti
          v-for="(gewinnungsanlage, index) in gewinnungsanlagen"
          :key="gewinnungsanlage.id"
          :index="index"
          :data="gewinnungsanlagen"
          :dataSingle="gewinnungsanlage"
          :dataLy="gewinnungsanlagenLy"
          labelInputLabel="Bezeichnung der Wassergewinnungsanlage"
          fieldGroup="gewinnungsanlagen"
          idFieldName="FOENR_GES"
          :readonly="readonly"
          deletable="true"
          type="EG"
          :year="year"
          :lg="lg"
          :val1="gewinnungsanlage.value1"
          :val2="gewinnungsanlage.value2"
          val1LyName="FOEMENG_2"
          val2LyName="FOEMENG_1"
          sumLyName="FOEMENG_G"
          :OSSTOCKWERK="gewinnungsanlage.OSSTOCKWERK"
          :OSHORIZONT="gewinnungsanlage.OSHORIZONT"
          @toggleMenu="toggleMenu"
          @updateValue="updateValue"
          @savePreviousId="savePreviousId"
          @sendIdUpdateRequest="sendIdUpdateRequest"
          @deleteEgRow="deleteEgRow"
          @handleInput="handleInput"
          @formatDigitsOnly="formatDigitsOnly"
        />
        <FormRowMultiSum
          :group="gewinnungsanlagen"
          :lg="lg"
          :readonly="readonly"
          sumText="Summe Eigengewinnung"
          :value1="calculateSum(gewinnungsanlagen, '2').toLocaleString('de-DE')"
          :value2="calculateSum(gewinnungsanlagen, '1').toLocaleString('de-DE')"
          :sum="
            calculateSum(gewinnungsanlagen, 'total').toLocaleString('de-DE')
          "
          :value1Ly="GESGW_2_ly"
          :value2Ly="GESGW_1_ly"
          :sumLy="GESGWG_G_ly"
          :year="year"
          :addBtn="true"
          @add="add"
        />
      </v-card>

      <v-divider class="mt-5 mb-5"></v-divider>

      <v-expansion-panels class="oberfl">
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div>
              <h3 class="expansion__hl">Eigengewinnung</h3>
              <span>Oberflächenwasser</span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="0">
              <FormRowMulti
                v-for="(
                  gewinnungsanlageOberflaeche, index
                ) in gewinnungsanlagenOberflaeche"
                :key="gewinnungsanlageOberflaeche.id"
                :index="index"
                :data="gewinnungsanlagenOberflaeche"
                :dataSingle="gewinnungsanlageOberflaeche"
                :dataLy="gewinnungsanlagenOberflaecheLy"
                labelInputLabel="Bezeichnung der Wassergewinnungsanlage"
                fieldGroup="gewinnungsanlagenOberflaeche"
                idFieldName="FOENR_GES"
                :readonly="readonly"
                deletable="true"
                type="EGO"
                :year="year"
                :lg="lg"
                :val1="gewinnungsanlageOberflaeche.value1"
                :val2="gewinnungsanlageOberflaeche.value2"
                val1LyName="FOEMENG_2"
                val2LyName="FOEMENG_1"
                sumLyName="FOEMENG_G"
                @toggleMenu="toggleMenu"
                @updateValue="updateValue"
                @savePreviousId="savePreviousId"
                @sendIdUpdateRequest="sendIdUpdateRequest"
                @deleteEgRow="deleteEgRow"
                @handleInput="handleInput"
                @formatDigitsOnly="formatDigitsOnly"
              />
              <FormRowMultiSum
                :group="gewinnungsanlagenOberflaeche"
                :lg="lg"
                :readonly="readonly"
                sumText="Summe Eigengewinnung"
                :value1="
                  calculateSum(
                    gewinnungsanlagenOberflaeche,
                    '2'
                  ).toLocaleString('de-DE')
                "
                :value2="
                  calculateSum(
                    gewinnungsanlagenOberflaeche,
                    '1'
                  ).toLocaleString('de-DE')
                "
                :sum="
                  calculateSum(
                    gewinnungsanlagenOberflaeche,
                    'total'
                  ).toLocaleString('de-DE')
                "
                :value1Ly="MENGEOW_2_ly"
                :value2Ly="MENGEOW_1_ly"
                :sumLy="MENGEOW_G_ly"
                :year="year"
                :addBtn="true"
                @add="add"
              />
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-divider class="mt-5 mb-5"></v-divider>
      <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
        <div class="icon-wrapper">
          <v-icon>mdi-plus</v-icon>
        </div>
        <v-row dense>
          <v-col cols="12">
            <h3>Fremdbezug</h3>
          </v-col>
        </v-row>
        <FormRowMulti
          v-for="(wasserlieferant, index) in wasserlieferanten"
          :key="wasserlieferant.id"
          :index="index"
          :data="wasserlieferanten"
          :dataSingle="wasserlieferant"
          :dataLy="wasserlieferantenLy"
          labelInputLabel="Bezeichnung des Wasserlieferanten"
          fieldGroup="wasserlieferanten"
          :readonly="readonly"
          :deletable="true"
          :year="year"
          :lg="lg"
          :val1="wasserlieferant.value1"
          :val2="wasserlieferant.value2"
          :val1Ly="
            wasserlieferantenLy[index]
              ? wasserlieferantenLy[index].value1
              : null
          "
          :val2Ly="
            wasserlieferantenLy[index]
              ? wasserlieferantenLy[index].value2
              : null
          "
          :sumLy="
            wasserlieferantenLy[index] ? wasserlieferantenLy[index].sum : null
          "
          :variant="wasserlieferant.bezugsart"
          variantName="bezugsart"
          variantLabel="Bezugsart"
          variantHint="Bezugsart (e = Ersatzwasser, a = außerhalb EV, s = sonstiges Wasser)"
          @toggleMenu="toggleMenu"
          @updateValue="updateValue"
          @savePreviousId="savePreviousId"
          @sendIdUpdateRequest="sendIdUpdateRequest"
          @deleteEgRow="deleteEgRow"
          @handleInput="handleInput"
          @formatDigitsOnly="formatDigitsOnly"
          @remove="remove"
        />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="add(wasserlieferanten, true)"
              fab
              dark
              small
              color="primary"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon v-show="!printing">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Zeile hinzufügen</span>
        </v-tooltip>
      </v-card>
      <v-divider class="mt-5 mb-5"></v-divider>
      <v-card
        class="ps-3 pe-3 pt-3 pb-3 blue__border"
        elevation="1"
        id="eigen-fremd-summe"
      >
        <div class="icon-wrapper">
          <v-icon>mdi-equal</v-icon>
        </div>
        <FormRowMultiSum
          :group="wasserlieferanten"
          :lg="lg"
          :readonly="readonly"
          sumText="∑ Eigengewinnung & Fremdbezug"
          :value1="calculateWaterOccurrence('2').toLocaleString('de-DE')"
          :value2="calculateWaterOccurrence('1').toLocaleString('de-DE')"
          :sum="calculateWaterOccurrence('total').toLocaleString('de-DE')"
          :value1Ly="AUFKOM_2_ly"
          :value2Ly="AUFKOM_1_ly"
          :sumLy="AUFKOM_G_ly"
          :year="year"
          :addBtn="false"
          @add="add"
        />
        <v-row v-if="eigenFremdSummeHint !== ''">
          <v-col>
            <v-alert type="error">{{ eigenFremdSummeHint }}</v-alert>
            <v-checkbox
              label="Abweichung bestätigen"
              v-model="eigenFremdSummeCheck"
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card>
      <v-divider class="mt-5 mb-5"></v-divider>
      <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
        <div class="icon-wrapper">
          <v-icon>⁒</v-icon>
        </div>
        <v-row dense>
          <v-col cols="12">
            <h3>Fremdabgabe an die Wasserverteiler</h3>
          </v-col>
        </v-row>
        <FormRowMulti
          v-for="(wasserunterverteiler, index) in wasserunterverteilerPl"
          :key="wasserunterverteiler.id"
          :index="index"
          :data="wasserunterverteilerPl"
          :dataSingle="wasserunterverteiler"
          :dataLy="wasserunterverteilerLy"
          labelInputLabel="Abgabeziel"
          fieldGroup="wasserunterverteilerPl"
          :readonly="readonly"
          deletable="true"
          :year="year"
          :lg="lg"
          :val1="wasserunterverteiler.value1"
          :val2="wasserunterverteiler.value2"
          :val1Ly="
            wasserunterverteilerLy[index]
              ? wasserunterverteilerLy[index].value1
              : null
          "
          :val2Ly="
            wasserunterverteilerLy[index]
              ? wasserunterverteilerLy[index].value2
              : null
          "
          :sumLy="
            wasserunterverteilerLy[index]
              ? wasserunterverteilerLy[index].sum
              : null
          "
          :variant="wasserunterverteiler.abgabeart"
          variantName="abgabeart"
          variantLabel="Abgabeart"
          variantHint="Abgabeart (e = Ersatzwasser, a = außerhalb EV)"
          @toggleMenu="toggleMenu"
          @updateValue="updateValue"
          @savePreviousId="savePreviousId"
          @sendIdUpdateRequest="sendIdUpdateRequest"
          @deleteEgRow="deleteEgRow"
          @handleInput="handleInput"
          @formatDigitsOnly="formatDigitsOnly"
          @remove="remove"
        />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="add(wasserunterverteilerPl, true)"
              fab
              dark
              small
              color="primary"
              v-on="on"
              v-bind="attrs"
            >
              <v-icon v-show="!printing">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Zeile hinzufügen</span>
        </v-tooltip>
      </v-card>
      <v-divider class="mt-5 mb-5"></v-divider>
      <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
        <div class="icon-wrapper">
          <v-icon>⁒</v-icon>
        </div>
        <FormRow
          headline="Einleitung ohne Nutzung"
          headlineStyles="font-size: 1rem; font-weight: 400;"
          :data="einleitungOhneNutzung"
          :labelComment="einleitungOhneNutzung.labelComment"
          labelHint=""
          val1Name="EINL_2"
          :value1Comment="einleitungOhneNutzung.value1Comment"
          :val1_ly="EINL_2_ly"
          val2Name="EINL_1"
          :value2Comment="einleitungOhneNutzung.value2Comment"
          :val2_ly="EINL_1_ly"
          noteFieldName="EINL_note"
          :val1Dif="einleitungOhneNutzung.EINL_2Dif"
          :val2Dif="einleitungOhneNutzung.EINL_1Dif"
          :sum="EINL_G"
          :sum_ly="EINL_G_ly"
          fieldGroup="einleitungOhneNutzung"
          labelFieldName="EINL_label"
          labelFieldMaxLength="40"
          :variantFieldName="usertype === 1 ? 'EINL_KZ' : null"
          variantFieldMaxLength="1"
          variantFieldLabel="Kennung Einleitung"
          variantHint="a = Anreicherung, e = Einleitung ohne Nutzung"
          :year="year"
          :lg="lg"
          :readonly="readonly"
          :status="status"
          @updateValue="updateValue"
          @handleInputSingleRow="handleInputSingleRow"
          @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
          @toggleMenu="toggleMenu"
          @toggleBemerkung="toggleBemerkung"
        >
        </FormRow>
      </v-card>
      <v-divider class="mt-5 mb-5"></v-divider>
      <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
        <div class="icon-wrapper">
          <v-icon>mdi-equal</v-icon>
        </div>
        <FormRowMultiSum
          :lg="lg"
          :readonly="readonly"
          sumText="Wasserbedarf im eigenen Versorgungsgebiet"
          :value1="calculateBedarf('value1', true).toLocaleString('de-DE')"
          :value2="calculateBedarf('value2', true).toLocaleString('de-DE')"
          :sum="calculateBedarf('sum', true).toLocaleString('de-DE')"
          :value1Ly="BEDARF_2_ly"
          :value2Ly="BEDARF_1_ly"
          :sumLy="BEDARF_G_ly"
          :year="year"
          :addBtn="false"
        />
      </v-card>
      <v-divider class="mt-5 mb-5"></v-divider>
      <v-card class="ps-3 pe-3 pt-3 pb-3 blue__border" elevation="1">
        <v-row dense>
          <v-col cols="12" lg="4">
            <h3>Aufteilung des Wasserbedarfs</h3>
          </v-col>
        </v-row>

        <FormRow
          headline="Haushalte"
          :data="haushaltungen"
          val1Name="HAUSHALT_2"
          :value1Comment="haushaltungen.value1Comment"
          :val1_ly="HAUSHALT_2_ly"
          val2Name="HAUSHALT_1"
          :value2Comment="haushaltungen.value2Comment"
          :val2_ly="HAUSHALT_1_ly"
          :note="haushaltungen.note"
          noteFieldName="note"
          :val1Dif="haushaltungen.HAUSHALT_2Dif"
          :val2Dif="haushaltungen.HAUSHALT_1Dif"
          :sum="HAUSHALT_G"
          :sum_ly="HAUSHALT_G_ly"
          fieldGroup="haushaltungen"
          :year="year"
          :lg="lg"
          :readonly="readonly"
          :status="status"
          @updateValue="updateValue"
          @handleInputSingleRow="handleInputSingleRow"
          @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
          @toggleMenu="toggleMenu"
          @toggleBemerkung="toggleBemerkung"
        >
        </FormRow>

        <FormRow
          headline="Gewerbe und Landwirtschaft"
          headlineStyles="font-size: 1rem; font-weight: 700;"
          subline="Abnahme weniger als 20.000 m³/Jahr"
          :data="gewkl"
          val1Name="GEWKL_2"
          :value1Comment="gewkl.value1Comment"
          :val1_ly="GEWKL_2_ly"
          val2Name="GEWKL_1"
          :value2Comment="gewkl.value2Comment"
          :val2_ly="GEWKL_1_ly"
          :note="gewkl.note"
          noteFieldName="note"
          :val1Dif="gewkl.GEWKL_2Dif"
          :val2Dif="gewkl.GEWKL_1Dif"
          :sum="GEWKL_G"
          :sum_ly="GEWKL_G_ly"
          fieldGroup="gewkl"
          :year="year"
          :lg="lg"
          :readonly="readonly"
          :status="status"
          @updateValue="updateValue"
          @handleInputSingleRow="handleInputSingleRow"
          @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
          @toggleMenu="toggleMenu"
          @toggleBemerkung="toggleBemerkung"
        >
        </FormRow>
        <FormRow
          headline="Gewerbe und Landwirtschaft"
          headlineStyles="font-size: 1rem; font-weight: 700;"
          subline="Abnahme mindestens 20.000 m³/Jahr"
          :data="gewgr"
          val1Name="GEWGR_2"
          :value1Comment="gewgr.value1Comment"
          :val1_ly="GEWGR_2_ly"
          val2Name="GEWGR_1"
          :value2Comment="gewgr.value2Comment"
          :val2_ly="GEWGR_1_ly"
          :note="gewgr.note"
          noteFieldName="note"
          :val1Dif="gewgr.GEWGR_2Dif"
          :val2Dif="gewgr.GEWGR_1Dif"
          :sum="GEWGR_G"
          :sum_ly="GEWGR_G_ly"
          fieldGroup="gewgr"
          :year="year"
          :lg="lg"
          :readonly="readonly"
          :status="status"
          @updateValue="updateValue"
          @handleInputSingleRow="handleInputSingleRow"
          @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
          @toggleMenu="toggleMenu"
          @toggleBemerkung="toggleBemerkung"
        >
        </FormRow>

        <FormRow
          headline="Öffentliche Einrichtungen"
          headlineStyles="font-size: 1rem; font-weight: 700;"
          subline="Pflegestätten, Schulen, Kasernen, Feuerschutz, Städtehygiene, Verwaltungen, Grünanlagen usw."
          :data="oeffentl"
          val1Name="OEFFENTL_2"
          :value1Comment="oeffentl.value1Comment"
          :val1_ly="OEFFENTL_2_ly"
          val2Name="OEFFENTL_1"
          :value2Comment="oeffentl.value2Comment"
          :val2_ly="OEFFENTL_1_ly"
          :note="oeffentl.note"
          noteFieldName="note"
          :val1Dif="oeffentl.OEFFENTL_2Dif"
          :val2Dif="oeffentl.OEFFENTL_1Dif"
          :sum="OEFFENTL_G"
          :sum_ly="OEFFENTL_G_ly"
          fieldGroup="oeffentl"
          :year="year"
          :lg="lg"
          :readonly="readonly"
          :status="status"
          @updateValue="updateValue"
          @handleInputSingleRow="handleInputSingleRow"
          @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
          @toggleMenu="toggleMenu"
          @toggleBemerkung="toggleBemerkung"
        >
        </FormRow>

        <FormRow
          headline="Wasserverluste"
          headlineStyles="font-size: 1rem; font-weight: 700;"
          :data="verluste"
          val1Name="VERLUSTE_2"
          :value1Comment="verluste.value1Comment"
          :val1_ly="VERLUSTE_2_ly"
          val2Name="VERLUSTE_1"
          :value2Comment="verluste.value2Comment"
          :val2_ly="VERLUSTE_1_ly"
          :note="verluste.note"
          noteFieldName="note"
          :val1Dif="verluste.VERLUSTE_2Dif"
          :val2Dif="verluste.VERLUSTE_1Dif"
          :sum="VERLUSTE_G"
          :sum_ly="VERLUSTE_G_ly"
          fieldGroup="verluste"
          :year="year"
          :lg="lg"
          :readonly="readonly"
          :status="status"
          @updateValue="updateValue"
          @handleInputSingleRow="handleInputSingleRow"
          @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
          @toggleMenu="toggleMenu"
          @toggleBemerkung="toggleBemerkung"
        >
        </FormRow>

        <FormRow
          headline="Eigengebrauch des Wasserwerks"
          headlineStyles="font-size: 1rem; font-weight: 700;"
          :data="eigenv"
          val1Name="EIGENV_2"
          :value1Comment="eigenv.value1Comment"
          :val1_ly="EIGENV_2_ly"
          val2Name="EIGENV_1"
          :value2Comment="eigenv.value2Comment"
          :val2_ly="EIGENV_1_ly"
          :note="eigenv.note"
          noteFieldName="note"
          :val1Dif="eigenv.EIGENV_2Dif"
          :val2Dif="eigenv.EIGENV_1Dif"
          :sum="EIGENV_G"
          :sum_ly="EIGENV_G_ly"
          fieldGroup="eigenv"
          :year="year"
          :lg="lg"
          :readonly="readonly"
          :status="status"
          @updateValue="updateValue"
          @handleInputSingleRow="handleInputSingleRow"
          @formatDigitsOnlySingleRow="formatDigitsOnlySingleRow"
          @toggleMenu="toggleMenu"
          @toggleBemerkung="toggleBemerkung"
        >
        </FormRow>
      </v-card>
      <v-divider class="mt-5 mb-5"></v-divider>
      <v-row dense data-section="row">
        <v-col cols="12" class="relative">
          <v-textarea
            outlined
            name="allgemeineBemerkungen"
            label="Allgemeine Bemerkungen"
            placeholder="Tragen Sie hier gegebenenfalls allgemeine Bemerkungen ein"
            v-model="allgemeineBemerkungen"
            data-tabindex="1"
          >
          </v-textarea>
          <v-textarea
            outlined
            name="wassergewinnungsrechte"
            label="Geltende Rechte zur Wassergewinnung"
            placeholder="Bitte notieren Sie hier ihre geltenden Rechte zur Wassergewinnung"
            v-model="wassergewinnungsrechte"
            data-tabindex="2"
          ></v-textarea>
          <v-textarea
            outlined
            name="versorgungsgebietsveraenderungen"
            label="Veränderungen des Versorgungsgebiets während des Erfassungszeitraums"
            placeholder="Notieren Sie bitte etwaige Veränderungen"
            v-model="versorgungsgebietsveraenderungen"
            data-tabindex="3"
          >
          </v-textarea>
        </v-col>
      </v-row>

      <v-divider class="mt-5 mb-5"></v-divider>
      <v-card
        class="ps-3 pe-3 pt-3 pb-3 mb-7"
        elevation="1"
        v-show="
          status === 'freigegeben' || usertype === 1 || status === 'pruefung'
            ? false
            : true
        "
      >
        <v-row dense class="d-flex align-center">
          <v-col cols="1" class="d-flex justify-center">
            <v-icon color="primary">mdi-information-outline</v-icon>
          </v-col>
          <v-col>
            <p class="mb-0">
              Die Beantwortung der vorgenannten Fragen kann zu einer
              kostenpflichtigen Mitgliedschaft im Erftverband führen, sofern die
              Bedingungen gemäß Erftverbandsgesetz erfüllt sind. Das
              Erftverbandsgesetz ist im Internet unter
              <a href="https://www.erftverband.de">www.erftverband.de</a>
              abrufbar. Gerne senden wir es Ihnen auch in Papierform zu und
              stehen für etwaige Fragen zur Verfügung.
            </p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="1"></v-col>
          <v-col sm="9" md="6" lg="4">
            <v-checkbox
              v-model="terms"
              label="Ich habe den Hinweis gelesen und akzeptiere ihn"
              :rules="[
                (v) =>
                  !!v || 'Bitte akzeptieren Sie den Hinweis um fortzufahren',
              ]"
              required
            >
            </v-checkbox>
          </v-col>
        </v-row>
      </v-card>
      <v-row dense align="center" v-if="usertype === 1">
        <v-col>
          <FormStamp
            :data="stampData"
            name="stampData"
            @updateValue="updateValue"
          ></FormStamp>
        </v-col>
        <v-col>
          <InternalNotes
            :hinweiseFreigebenden="internalNotes.hinweiseFreigebenden"
            :hinweisePruefenden="internalNotes.hinweisePruefenden"
            @updateValue="updateValue"
          />
        </v-col>
      </v-row>
      <v-row dense align="center">
        <v-col
          v-if="status === 'erste_freigabe' && this.usertype === 1"
          class="d-flex justify-end"
          style="text-align: end"
        >
          zuletzt bearbeitet von {{ this.lastchange_user }}
        </v-col>
      </v-row>
      <v-row
        v-if="
          (status === 'erste_freigabe' || status === 'freigegeben') &&
          this.usertype === 1
        "
      >
        <v-col class="d-flex flex-column align-end">
          <div v-if="status === 'erste_freigabe' || status === 'freigegeben'">
            Erste Freigabe
            {{ freigeber_1 ? 'von ' + freigeber_1 : ''
            }}{{ datum_freigabe_1 ? ' am ' + datum_freigabe_1 : ''
            }}{{ zeit_freigabe_1 ? ' um ' + zeit_freigabe_1 : '' }}.
          </div>
          <div v-if="status === 'freigegeben'">
            Endgültige Freigabe
            {{ freigeber_2 ? 'von ' + freigeber_2 : ''
            }}{{ datum_freigabe_2 ? ' am ' + datum_freigabe_2 : ''
            }}{{ zeit_freigabe_2 ? ' um ' + zeit_freigabe_2 : '' }}.
          </div>
        </v-col>
      </v-row>
      <v-row
        dense
        align="center"
        class="mb-5 d-print-none"
        justify="space-between"
        data-section="row"
      >
        <div class="mb-4 mb-sm-0 mt-5">
          <v-btn
            @click="toDashboard(usertype)"
            v-show="
              status === 'freigegeben' ||
              (status === 'pruefung' && usertype !== 1)
                ? false
                : true
            "
            data-tabindex="1"
          >
            Zurück zum Dashboard
          </v-btn>
        </div>
        <div>
          <v-btn
            class="mr-4 mr-sm-10 mt-5"
            @click="onSave(true)"
            v-show="
              status === 'freigegeben' ||
              (status === 'pruefung' && usertype !== 1) ||
              readonly
                ? false
                : true
            "
            data-tabindex="2"
          >
            Zwischenspeichern
          </v-btn>
          <v-btn
            class="mr-4 mr-sm-10 mt-5"
            type="submit"
            v-show="
              status === 'freigegeben' ||
              (status === 'pruefung' && usertype !== 1) ||
              (readonly && status !== 'erste_freigabe') ||
              (readonly && usertype !== 1)
                ? false
                : true
            "
            :color="usertype !== 1 ? 'primary' : 'orange'"
            data-tabindex="3"
          >
            {{
              usertype !== 1 || status === '' || status === 'bearbeitung'
                ? 'An den Erftverband senden'
                : status === 'erste_freigabe'
                ? 'Zurück zur Prüfung'
                : 'Zurück zum Entnehmer'
            }}
          </v-btn>
          <v-btn
            class="mr-4 mr-sm-10 mt-5"
            v-if="
              usertype === 1 &&
              (status === 'ausstehend' || status === 'korrektur')
            "
            color="primary"
            @click="sendWStatusPruefung()"
            data-tabindex="4"
          >
            Formular übernehmen
          </v-btn>
          <v-btn
            v-if="
              ((status !== 'erste_freigabe' && erste_freigabe) ||
                (status === 'erste_freigabe' && zweite_freigabe)) &&
              !readonly
            "
            @click="onAccept"
            class="mt-5"
            color="primary"
            data-tabindex="5"
          >
            {{
              status === 'erste_freigabe'
                ? 'Endgültig freigeben'
                : 'Erste Freigabe'
            }}
          </v-btn>
          <v-tooltip
            top
            v-else-if="
              usertype === 1 &&
              status !== 'freigegeben' &&
              status !== 'ausstehend' &&
              status !== 'bearbeitung' &&
              status !== 'korrektur'
            "
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                style="opacity: 0.5; background-color: red"
                class="mt-5"
                data-tabindex="6"
              >
                {{
                  status === 'erste_freigabe'
                    ? 'Endgültig freigeben'
                    : 'Erste Freigabe'
                }}
              </v-btn>
            </template>
            <span>Fehlende Berechtigung</span>
          </v-tooltip>
        </div>
      </v-row>
    </v-container>
    <v-container
      v-show="
        status === 'freigegeben' || (status === 'pruefung' && usertype !== 1)
          ? true
          : false
      "
      class="d-print-none"
    >
      <v-row data-section="row">
        <div class="mb-4 mt-4">
          <v-btn @click="toDashboard(usertype)" data-tabindex="1">
            Zurück zum Dashboard
          </v-btn>
        </div>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import helpers from '../helpers.js';
const {
  getInternalNotes,
  stopAutosaveAfterX,
  getSingleRowComments,
  determineSingleRowDif,
  getSingleRowSaveData,
  entryIsNotEmpty,
  buildNewPreviousData,
  assemblePreviousData,
  observeDOMMutations,
  removeTabListener,
  detectTabKey,
  handleTabKey,
  deleteEgRow,
  deleteRow,
  savePreviousId,
  sendIdUpdateRequest,
  getStampDataToSend,
  getStampData,
  sendWStatusPruefung,
  getEigengewinnungComments,
  getEigengewinnungOberflaecheComments,
  multisort,
  getFreigeber,
  getEigengewinnung,
  getEigengewinnungOberflaeche,
  getFremdbezug,
  getFremdabgabe,
  getSingleRowValues,
  currentDate,
  currentYear,
  add,
  remove,
  createGroups,
  sumValues,
  formatDigitsOnly,
  formatDigitsOnlySingleRow,
  handleInput,
  handleInputSingleRow,
  calculateValue,
  calculateBedarf,
  toNumber,
  toDashboard,
  toggleBemerkung,
  toggleMenu,
  updateValue,
  checkCommentsOpened,
  setDataLoaded,
  handleScroll,
  readonly,
  getRelativeDifference,
  beforeLoadForm,
  getNote,
  setStatusesOnLoad,
  progressState,
} = helpers;
import { mapState } from 'vuex';
import axios from '../../utils/api';
import { getAuthToken, getUserInfo } from '../../utils/auth.js';
import FormRow from './FormRow.vue';
import goTo from '../../node_modules/vuetify/lib/services/goto';
import FormStamp from './FormStamp.vue';
import FormLegend from './FormLegend.vue';
import FormRowMulti from './FormRowMulti.vue';
import FormRowMultiSum from './FormRowMultiSum.vue';
import InternalNotes from './InternalNotes.vue';
import FirstSubBtn from './FirstSubBtn.vue';

export default {
  components: {
    FormRow,
    FormStamp,
    FormLegend,
    FormRowMulti,
    FormRowMultiSum,
    InternalNotes,
    FirstSubBtn,
  },
  data: () => ({
    internalNotes: {
      hinweiseFreigebenden: '',
      hinweisePruefenden: '',
    },
    fremdbezugMaxLength: 10,
    fremdabgabeMaxLength: 8,
    stampData: {},
    freigeber_1: null,
    datum_freigabe_1: null,
    zeit_freigabe_1: null,
    freigeber_2: null,
    datum_freigabe_2: null,
    zeit_freigabe_2: null,
    lastchange_user: null,
    berechtigung: null,
    erste_freigabe: false,
    zweite_freigabe: false,
    eigenFremdSummeCheck: false,
    eigenFremdSummeHint: '',
    dataLoaded: false,
    timer: null,
    user: '',
    formtype: 'OEFF',
    year: '',
    mode: '',
    lg: true,
    valid: true,
    terms: false,
    city: '',
    gewinnungsanlagen: [],
    gewinnungsanlagenOberflaeche: [],
    grundwasserfoerdermenge: {
      GESGW_2: 0,
      GESGW_1: 0,
      GESGWG_G: 0,
    },
    wasserlieferanten: [],
    wasseraufkommen: {
      AUFKOM_2: 0,
      AUFKOM_1: 0,
      AUFKOM_G: 0,
    },
    wasserunterverteilerPl: [],
    einleitungOhneNutzung: {
      inputs: {
        EINL_2: '0',
        EINL_1: '0',
      },
      EINL_label: '',
      EINL_note: '',
      EINL_KZ: '',
      bemerkungMenu: false,
      labelCommentMenu: false,
      labelCommentOpened: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    wasserbedarf: {
      BEDARF_1: 0,
      BEDARF_2: 0,
      BEDARF_G: 0,
    },
    haushaltungen: {
      inputs: {
        HAUSHALT_2: '0',
        HAUSHALT_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    gewkl: {
      inputs: {
        GEWKL_2: '0',
        GEWKL_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    gewgr: {
      inputs: {
        GEWGR_2: '0',
        GEWGR_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    oeffentl: {
      inputs: {
        OEFFENTL_2: '0',
        OEFFENTL_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    verluste: {
      inputs: {
        VERLUSTE_2: '0',
        VERLUSTE_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    eigenv: {
      inputs: {
        EIGENV_2: '0',
        EIGENV_1: '0',
      },
      note: '',
      bemerkungMenu: false,
      value1CommentMenu: false,
      value2CommentMenu: false,
      value1CommentOpened: false,
      value2CommentOpened: false,
    },
    allgemeineBemerkungen: '',
    wassergewinnungsrechte: '',
    versorgungsgebietsveraenderungen: '',
    saveData: {},
    GESGW_2_ly: 0,
    GESGW_1_ly: 0,
    GESGWG_G_ly: 0,
    MENGEOW_2_ly: 0,
    MENGEOW_1_ly: 0,
    MENGEOW_G_ly: 0,
    AUFKOM_2_ly: 0,
    AUFKOM_1_ly: 0,
    AUFKOM_G_ly: 0,
    EINL_2_ly: 0,
    EINL_1_ly: 0,
    EINL_G_ly: 0,
    BEDARF_2_ly: 0,
    BEDARF_1_ly: 0,
    BEDARF_G_ly: 0,
    HAUSHALT_2_ly: 0,
    HAUSHALT_1_ly: 0,
    HAUSHALT_G_ly: 0,
    GEWKL_2_ly: 0,
    GEWKL_1_ly: 0,
    GEWKL_G_ly: 0,
    GEWGR_2_ly: 0,
    GEWGR_1_ly: 0,
    GEWGR_G_ly: 0,
    OEFFENTL_2_ly: 0,
    OEFFENTL_1_ly: 0,
    OEFFENTL_G_ly: 0,
    VERLUSTE_2_ly: 0,
    VERLUSTE_1_ly: 0,
    VERLUSTE_G_ly: 0,
    EIGENV_2_ly: 0,
    EIGENV_1_ly: 0,
    EIGENV_G_ly: 0,
    gewinnungsanlagenLy: [],
    gewinnungsanlagenOberflaecheLy: [],
    wasserlieferantenLy: [],
    wasserunterverteilerLy: [],
    newPreviousData: {},
  }),

  computed: {
    readonly,
    ...mapState({
      loggedIn: (state) => state.loggedIn,
      username: (state) => state.username,
      usertype: (state) => state.usertype,
      showNavDrawer: (state) => state.showNavDrawer,
      status: (state) => state.status,
      loading: (state) => state.loading,
      usermail: (state) => state.usermail,
      printing: (state) => state.printing,
    }),
    EINL_G() {
      let result = this.sumValues(this.einleitungOhneNutzung.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    HAUSHALT_G() {
      let result = this.sumValues(this.haushaltungen.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    GEWKL_G() {
      let result = this.sumValues(this.gewkl.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    GEWGR_G() {
      let result = this.sumValues(this.gewgr.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    OEFFENTL_G() {
      let result = this.sumValues(this.oeffentl.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    VERLUSTE_G() {
      let result = this.sumValues(this.verluste.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
    EIGENV_G() {
      let result = this.sumValues(this.eigenv.inputs);
      result = result.toLocaleString('de-DE');
      return result;
    },
  },
  async created() {
    await this.beforeLoadForm();
    this.year = this.$route.params.year;
    this.user = this.$route.params.username;
    this.mode = this.$route.params.mode ? this.$route.params.mode : '';

    const res = await axios.get('/API-DB/getFormData', {
      params: {
        formtype: this.formtype,
        user: this.user,
        jahr: this.year,
        mode: this.mode,
      },
    });
    console.log('res ', res);
    this.saveData = res.data;
    this.getFreigeber(this.saveData);

    this.lastchange_user = this.saveData.lastchange_user;

    // account for previousData being empty array or empty object
    if (this.saveData.previousData) {
      if (
        this.saveData.previousData === null ||
        this.saveData.previousData == [] ||
        this.saveData.previousData == {} ||
        this.saveData.previousData.length === 0 ||
        Object.keys(this.saveData.previousData).length === 0
      ) {
        delete this.saveData.previousData;
        console.log('deleted empty previousData');
      }
    }

    this.setStatusesOnLoad();

    const fillLastYearsValues = () => {
      if (this.status !== 'bearbeitung' && this.saveData.previousData) {
        if (typeof this.saveData.previousData.GESGW_2 !== 'number') {
          this.GESGW_2_ly = parseFloat(
            this.saveData.previousData.GESGW_2.replaceAll('.', '')
          ).toLocaleString('de-DE');
        } else {
          this.GESGW_2_ly =
            this.saveData.previousData.GESGW_2.toLocaleString('de-DE');
        }
        if (typeof this.saveData.previousData.GESGW_1 !== 'number') {
          this.GESGW_1_ly = parseFloat(
            this.saveData.previousData.GESGW_1.replaceAll('.', '')
          ).toLocaleString('de-DE');
        } else {
          this.GESGW_1_ly =
            this.saveData.previousData.GESGW_1.toLocaleString('de-DE');
        }
        if (typeof this.saveData.previousData.GESGW_G !== 'number') {
          this.GESGWG_G_ly = parseFloat(
            this.saveData.previousData.GESGWG_G.replaceAll('.', '')
          ).toLocaleString('de-DE');
        } else {
          this.GESGWG_G_ly =
            this.saveData.previousData.GESGWG_G.toLocaleString('de-DE');
        }
      }
      this.getEigengewinnung();
      this.getEigengewinnungOberflaeche('gewinnungsanlagenOberflaeche');

      let MENGEOW_2_ly = 0;
      let MENGEOW_1_ly = 0;
      let MENGEOW_G_ly = 0;
      for (let i = 0; i < this.gewinnungsanlagenOberflaecheLy.length; i++) {
        MENGEOW_2_ly =
          MENGEOW_2_ly +
          parseInt(
            this.gewinnungsanlagenOberflaecheLy[i].FOEMENG_2.replaceAll('.', '')
          );
        MENGEOW_1_ly =
          MENGEOW_1_ly +
          parseInt(
            this.gewinnungsanlagenOberflaecheLy[i].FOEMENG_1.replaceAll('.', '')
          );
        MENGEOW_G_ly =
          MENGEOW_G_ly +
          parseInt(
            this.gewinnungsanlagenOberflaecheLy[i].FOEMENG_G.replaceAll('.', '')
          );
      }
      this.MENGEOW_2_ly = MENGEOW_2_ly.toLocaleString('de-DE');
      this.MENGEOW_1_ly = MENGEOW_1_ly.toLocaleString('de-DE');
      this.MENGEOW_G_ly = MENGEOW_G_ly.toLocaleString('de-DE');

      this.getFremdbezug('BEZM', 'HERK', 'BEZART');
      this.getFremdabgabe('wasserunterverteilerPl', 'ABG', 'ZIEL', 'ABGART');

      this.getSingleRowValues('AUFKOM');
      this.getSingleRowValues('EINL');
      if (this.saveData) {
        this.einleitungOhneNutzung.inputs.EINL_2 = parseFloat(
          this.saveData['EINL_2']
        ).toLocaleString('de-DE');
        this.einleitungOhneNutzung.inputs.EINL_1 = parseFloat(
          this.saveData['EINL_1']
        ).toLocaleString('de-DE');
        if (this.saveData['EINL_note']) {
          this.einleitungOhneNutzung.EINL_note = this.saveData['EINL_note'];
        }
        if (this.saveData['EINL_label']) {
          this.einleitungOhneNutzung.EINL_label = this.saveData['EINL_label'];
        }
        if (this.saveData['EINL_KZ']) {
          this.einleitungOhneNutzung.EINL_KZ = this.saveData['EINL_KZ'];
        }
      }
      this.getSingleRowComments('einleitungOhneNutzung', 'EINL', true);
      this.determineSingleRowDif('einleitungOhneNutzung', 'EINL', true);
      this.getSingleRowValues('BEDARF');
      this.getSingleRowValues('HAUSHALT');
      if (this.saveData) {
        this.haushaltungen.inputs.HAUSHALT_2 = parseFloat(
          this.saveData['HAUSHALT_2']
        ).toLocaleString('de-DE');
        this.haushaltungen.inputs.HAUSHALT_1 = parseFloat(
          this.saveData['HAUSHALT_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('haushaltungen', 'HAUSHALT');
      this.getSingleRowComments('haushaltungen', 'HAUSHALT');
      this.determineSingleRowDif('haushaltungen', 'HAUSHALT');
      this.getSingleRowValues('GEWKL');
      if (this.saveData) {
        this.gewkl.inputs.GEWKL_2 = parseFloat(
          this.saveData['GEWKL_2']
        ).toLocaleString('de-DE');
        this.gewkl.inputs.GEWKL_1 = parseFloat(
          this.saveData['GEWKL_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('gewkl', 'GEWKL');
      this.getSingleRowComments('gewkl', 'GEWKL');
      this.determineSingleRowDif('gewkl', 'GEWKL');
      this.getSingleRowValues('GEWGR');
      if (this.saveData) {
        this.gewgr.inputs.GEWGR_2 = parseFloat(
          this.saveData['GEWGR_2']
        ).toLocaleString('de-DE');
        this.gewgr.inputs.GEWGR_1 = parseFloat(
          this.saveData['GEWGR_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('gewgr', 'GEWGR');
      this.getSingleRowComments('gewgr', 'GEWGR');
      this.determineSingleRowDif('gewgr', 'GEWGR');
      this.getSingleRowValues('OEFFENTL');
      if (this.saveData) {
        this.oeffentl.inputs.OEFFENTL_2 = parseFloat(
          this.saveData['OEFFENTL_2']
        ).toLocaleString('de-DE');
        this.oeffentl.inputs.OEFFENTL_1 = parseFloat(
          this.saveData['OEFFENTL_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('oeffentl', 'OEFFENTL');
      this.getSingleRowComments('oeffentl', 'OEFFENTL');
      this.determineSingleRowDif('oeffentl', 'OEFFENTL');
      this.getSingleRowValues('VERLUSTE');
      if (this.saveData) {
        this.verluste.inputs.VERLUSTE_2 = parseFloat(
          this.saveData['VERLUSTE_2']
        ).toLocaleString('de-DE');
        this.verluste.inputs.VERLUSTE_1 = parseFloat(
          this.saveData['VERLUSTE_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('verluste', 'VERLUSTE');
      this.getSingleRowComments('verluste', 'VERLUSTE');
      this.determineSingleRowDif('verluste', 'VERLUSTE');
      this.getSingleRowValues('EIGENV');
      if (this.saveData) {
        this.eigenv.inputs.EIGENV_2 = parseFloat(
          this.saveData['EIGENV_2']
        ).toLocaleString('de-DE');
        this.eigenv.inputs.EIGENV_1 = parseFloat(
          this.saveData['EIGENV_1']
        ).toLocaleString('de-DE');
      }
      this.getNote('eigenv', 'EIGENV');
      this.getSingleRowComments('eigenv', 'EIGENV');
      this.determineSingleRowDif('eigenv', 'EIGENV');
      this.getSingleRowValues('BEDARF');

      this.allgemeineBemerkungen = this.saveData.allgemeineBemerkungen;
      this.wassergewinnungsrechte = this.saveData.wassergewinnungsrechte;
      this.versorgungsgebietsveraenderungen =
        this.saveData.versorgungsgebietsveraenderungen;
    };

    await fillLastYearsValues();
    this.multisort(this.gewinnungsanlagen, 'label', [this.gewinnungsanlagenLy]);
    this.multisort(this.gewinnungsanlagenOberflaeche, 'label', [
      this.gewinnungsanlagenOberflaecheLy,
    ]);
    this.getEigengewinnungComments();
    this.getEigengewinnungOberflaecheComments('gewinnungsanlagenOberflaeche');

    this.$watch('EINL_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.einleitungOhneNutzung.sumChanged = true;
      }
    });

    this.$watch('HAUSHALT_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.haushaltungen.sumChanged = true;
      }
    });

    this.$watch('GEWKL_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.gewkl.sumChanged = true;
      }
    });

    this.$watch('GEWGR_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.gewgr.sumChanged = true;
      }
    });

    this.$watch('OEFFENTL_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.oeffentl.sumChanged = true;
      }
    });

    this.$watch('VERLUSTE_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.verluste.sumChanged = true;
      }
    });

    this.$watch('EIGENV_G', (newValue, oldValue) => {
      if (newValue !== oldValue) {
        this.eigenv.sumChanged = true;
      }
    });

    this.buildNewPreviousData(
      'wasserlieferanten',
      'BEZM',
      'HERK',
      'BEZART',
      'wasserunterverteilerPl',
      'ABG',
      'ZIEL',
      'ABGART'
    );

    // remove empty wasserlieferanten | 2 loops because index of el changes with removal
    let emptyWasserlieferanten = [];
    this.wasserlieferanten.forEach((el, index) => {
      if (!this.entryIsNotEmpty(index, 'wasserlieferanten')) {
        emptyWasserlieferanten.push(el);
      }
    });
    emptyWasserlieferanten.forEach((el) => {
      this.remove(
        this.wasserlieferanten,
        el,
        this.wasserlieferantenLy,
        'wasserlieferanten'
      );
    });

    // remove empty wasserunterverteiler | 2 loops because index of el changes with removal
    let emptyWasserunterverteiler = [];
    this.wasserunterverteilerPl.forEach((el, index) => {
      if (!this.entryIsNotEmpty(index, 'wasserunterverteilerPl')) {
        emptyWasserunterverteiler.push(el);
      }
    });
    emptyWasserunterverteiler.forEach((el) => {
      this.remove(
        this.wasserunterverteilerPl,
        el,
        this.wasserunterverteilerLy,
        'wasserunterverteilerPl'
      );
    });

    this.stampData = this.getStampData();
    this.getInternalNotes();
    await this.setDataLoaded(true);
    this.$forceUpdate();
    window.addEventListener('scroll', this.handleScroll);
    const tabSections = document.querySelectorAll('[data-section="row"]');
    await this.handleTabKey(tabSections);
    this.observeDOMMutations();
  },
  mounted() {
    this.$nextTick(() => {
      if (
        !(
          this.status === 'freigegeben' ||
          (this.status === 'pruefung' && this.usertype !== 1) ||
          this.readonly
        )
      ) {
        console.log('will save automatically');
        this.timer = setInterval(() => {
          this.onSave();
        }, 4 * 60 * 1000);
      }
      this.stopAutosaveAfterX(12 * 60 * 60 * 1000);
    });
  },
  beforeDestroy() {
    clearInterval(this.timer);
    window.removeEventListener('scroll', this.handleScroll);
    this.removeTabListener();
    this.DOMObserver.disconnect();
  },
  methods: {
    getInternalNotes,
    stopAutosaveAfterX,
    getSingleRowComments,
    determineSingleRowDif,
    buildNewPreviousData,
    assemblePreviousData,
    entryIsNotEmpty,
    observeDOMMutations,
    removeTabListener,
    detectTabKey,
    handleTabKey,
    deleteEgRow,
    deleteRow,
    savePreviousId,
    sendIdUpdateRequest,
    getStampDataToSend,
    getStampData,
    sendWStatusPruefung,
    getEigengewinnungComments,
    getEigengewinnungOberflaecheComments,
    multisort,
    getFreigeber,
    setStatusesOnLoad,
    getEigengewinnung,
    getEigengewinnungOberflaeche,
    getFremdbezug,
    getFremdabgabe,
    getNote,
    getUserInfo,
    beforeLoadForm,
    getRelativeDifference,
    setDataLoaded,
    handleScroll,
    updateValue,
    getAuthToken,
    toggleBemerkung,
    toggleMenu,
    toDashboard,
    formatDigitsOnly,
    formatDigitsOnlySingleRow,
    handleInput,
    handleInputSingleRow,
    getSingleRowValues,
    createGroups,
    currentDate,
    currentYear,
    async onSave(alerts) {
      // when only saving, not sending, set status to previous status
      if (this.saveData.status === 'ausstehend') {
        this.$store.dispatch('setStatusPending');
      } else if (this.saveData.status === 'bearbeitung') {
        this.$store.dispatch('setStatusBearbeitung');
      } else if (this.saveData.status === 'pruefung') {
        this.$store.dispatch('setStatusPruefung');
      } else if (this.saveData.status === 'korrektur') {
        this.$store.dispatch('setStatusKorrektur');
      }

      const params = new URLSearchParams({
        formtype: this.formtype,
        user: this.user,
        jahr: this.year,
      }).toString();
      const url = '/API-DB/formData?' + params;
      let { saveData } = this.getSaveData();
      console.log('saveData: ', saveData);
      const stringifiedSaveData = JSON.stringify(saveData);
      this.$store.dispatch('setLoadingTrue');

      try {
        let response = await axios.post(url, stringifiedSaveData, {
          headers: { 'Content-Type': 'application/json' },
        });
        console.log('response: ', response);
        if (alerts) {
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch('setAlertMsg', 'Formular gespeichert');
          this.$store.dispatch('setAlertType', 'info');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 3 * 1000);
        }
      } catch (error) {
        console.log('error: ', error);
        if (alerts) {
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch(
            'setAlertMsg',
            'Formular konnte nicht gespeichert werden'
          );
          this.$store.dispatch('setAlertType', 'error');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 5 * 1000);
        }
      }

      this.$store.dispatch('setLoadingFalse');
    },
    checkCommentsOpened,
    async onSubmit() {
      if (this.usertype !== 1) {
        if (
          !this.checkCommentsOpened(
            [
              this.gewinnungsanlagen,
              this.wasserlieferanten,
              this.wasserunterverteilerPl,
            ],
            [
              this.einleitungOhneNutzung,
              this.haushaltungen,
              this.gewkl,
              this.gewgr,
              this.oeffentl,
              this.verluste,
              this.eigenv,
            ]
          )
        ) {
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch(
            'setAlertMsg',
            'Bitte öffnen Sie alle Kommentare bevor Sie das Formular absenden.'
          );
          this.$store.dispatch('setAlertType', 'info');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 3 * 1000);
          return;
        }
        if (
          this.getRelativeDifference(
            this.wasseraufkommen.AUFKOM_G,
            this.AUFKOM_G_ly
          ) > 40 &&
          !(this.AUFKOM_G_ly === 0 || this.AUFKOM_G_ly === '0') &&
          !this.eigenFremdSummeCheck
        ) {
          this.eigenFremdSummeHint =
            'Bitte überprüfen Sie ihre Eingaben für die Eigengewinnung erneut. Die Abweichung zu den vorherigen Werten beträgt mehr als 40 Prozent.';
          goTo('#eigen-fremd-summe');
          return;
        }
        await this.$refs.form.validate();
      }
      if (this.valid === true || this.usertype === 1) {
        this.progressState();
        const params = new URLSearchParams({
          formtype: this.formtype,
          user: this.user,
          jahr: this.year,
        }).toString();
        const url = '/API-DB/formData?' + params;
        let { saveData } = this.getSaveData();
        console.log('saveData: ', saveData);
        const stringifiedSaveData = JSON.stringify(saveData);
        console.log('stringifiedSaveData: ', stringifiedSaveData);
        this.$store.dispatch('setLoadingTrue');
        try {
          let response = await axios.post(url, stringifiedSaveData, {
            headers: { 'Content-Type': 'application/json' },
          });
          console.log('response: ', response);
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch('setAlertMsg', 'Formular versandt');
          this.$store.dispatch('setAlertType', 'info');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 3 * 1000);
          this.$store.dispatch('setLoadingFalse');
          this.toDashboard(this.usertype);
        } catch (error) {
          console.log('error: ', error);
          this.$store.dispatch('setAlertShow', true);
          this.$store.dispatch(
            'setAlertMsg',
            'Formular konnte nicht versandt werden'
          );
          this.$store.dispatch('setAlertType', 'error');
          setTimeout(() => {
            this.$store.commit('setAlertShow', false);
          }, 5 * 1000);
          this.$store.dispatch('setLoadingFalse');
        }
      }
    },
    async onAccept() {
      if (!(this.erste_freigabe || this.zweite_freigabe)) {
        this.$store.dispatch('setAlertShow', true);
        this.$store.dispatch(
          'setAlertMsg',
          'Sie sind nicht berechtigt, Formulare freizugeben'
        );
        this.$store.dispatch('setAlertType', 'info');
        setTimeout(() => {
          this.$store.commit('setAlertShow', false);
        }, 3 * 1000);
        return;
      }
      if (this.status === 'erste_freigabe') {
        await this.$store.dispatch('setStatusFreigegeben');
      } else {
        await this.$store.dispatch('setStatusErsteFreigabe');
      }
      const params = new URLSearchParams({
        formtype: this.formtype,
        user: this.user,
        jahr: this.year,
      }).toString();
      const url = '/API-DB/formData?' + params;
      let { saveData } = this.getSaveData();
      if (this.status === 'erste_freigabe') {
        saveData.freigeber_1 = this.usermail;
      } else {
        saveData.freigeber_2 = this.usermail;
      }
      console.log('saveData: ', saveData);
      const stringifiedSaveData = JSON.stringify(saveData);
      console.log('stringifiedSaveData: ', stringifiedSaveData);
      this.$store.dispatch('setLoadingTrue');
      try {
        let response = await axios.post(url, stringifiedSaveData, {
          headers: { 'Content-Type': 'application/json' },
        });
        console.log('response: ', response);
        this.$store.dispatch('setAlertShow', true);
        this.$store.dispatch('setAlertMsg', 'Formular versandt');
        this.$store.dispatch('setAlertType', 'info');
        setTimeout(() => {
          this.$store.commit('setAlertShow', false);
        }, 3 * 1000);
        this.$store.dispatch('setLoadingFalse');
        this.toDashboard(this.usertype);
      } catch (error) {
        console.log('error: ', error);
        this.$store.dispatch('setAlertShow', true);
        this.$store.dispatch(
          'setAlertMsg',
          'Formular konnte nicht versandt werden'
        );
        this.$store.dispatch('setAlertType', 'error');
        setTimeout(() => {
          this.$store.commit('setAlertShow', false);
        }, 5 * 1000);
        this.$store.dispatch('setLoadingFalse');
      }
    },
    progressState,
    getSaveData() {
      let saveData = {};
      let comments = [];

      //  eigengewinnung
      let gewinnungsanlagen2ndSem = 0;
      let gewinnungsanlagen1stSem = 0;
      let gewinnungsanlagenSum = 0;
      for (let i = 0; i < this.gewinnungsanlagen.length; i++) {
        gewinnungsanlagen2ndSem =
          gewinnungsanlagen2ndSem +
          parseInt(this.gewinnungsanlagen[i].value1.replaceAll('.', ''));
        gewinnungsanlagen1stSem =
          gewinnungsanlagen1stSem +
          parseInt(this.gewinnungsanlagen[i].value2.replaceAll('.', ''));
        gewinnungsanlagenSum =
          gewinnungsanlagenSum +
          parseInt(this.gewinnungsanlagen[i].value1.replaceAll('.', '')) +
          parseInt(this.gewinnungsanlagen[i].value2.replaceAll('.', ''));
      }

      this.grundwasserfoerdermenge.GESGW_2 = gewinnungsanlagen2ndSem;
      this.grundwasserfoerdermenge.GESGW_1 = gewinnungsanlagen1stSem;
      this.grundwasserfoerdermenge.GESGWG_G = gewinnungsanlagenSum;

      saveData = { ...saveData, ...this.grundwasserfoerdermenge };

      // wasserlieferanten
      let wasserlieferantenSaveData = [];
      while (this.wasserlieferanten.length < this.fremdbezugMaxLength) {
        this.add(this.wasserlieferanten, true);
      }
      for (let i = 0; i < this.wasserlieferanten.length; i++) {
        let dbnum = 0;
        if (i <= 8) {
          dbnum = '0' + (i + 1);
        } else {
          dbnum = i + 1;
        }
        const label = 'HERK' + dbnum;
        const val1 = 'BEZM' + dbnum + '_2';
        const val2 = 'BEZM' + dbnum + '_1';
        const total = 'BEZM' + dbnum + '_G';
        const note = 'BEZM' + dbnum + '_note';
        const bezugsart = 'BEZART' + dbnum;

        wasserlieferantenSaveData[i] = {};
        wasserlieferantenSaveData[i][label] = this.wasserlieferanten[i].label;
        wasserlieferantenSaveData[i][val1] = parseInt(
          this.wasserlieferanten[i].value1.replaceAll('.', '')
        );
        wasserlieferantenSaveData[i][val2] = parseInt(
          this.wasserlieferanten[i].value2.replaceAll('.', '')
        );
        wasserlieferantenSaveData[i][total] = parseFloat(
          this.wasserlieferanten[i].sum.replaceAll('.', '')
        );
        wasserlieferantenSaveData[i][bezugsart] =
          this.wasserlieferanten[i].bezugsart;
        wasserlieferantenSaveData[i][note] = this.wasserlieferanten[i].note;

        wasserlieferantenSaveData.forEach((el) => {
          saveData = { ...saveData, ...el };
        });

        comments.push({
          key: label,
          value: this.wasserlieferanten[i].labelComment,
        });
        comments.push({
          key: val1,
          value: this.wasserlieferanten[i].value1Comment,
        });
        comments.push({
          key: val2,
          value: this.wasserlieferanten[i].value2Comment,
        });
      }

      let wasserunterverteilerSaveData = [];
      while (this.wasserunterverteilerPl.length < this.fremdabgabeMaxLength) {
        this.add(this.wasserunterverteilerPl, true);
      }
      for (let i = 0; i < this.wasserunterverteilerPl.length; i++) {
        const dbnum = '0' + (i + 1);
        const label = 'ZIEL' + dbnum;
        const val1 = 'ABG' + dbnum + '_2';
        const val2 = 'ABG' + dbnum + '_1';
        const total = 'ABG' + dbnum + '_G';
        const note = 'ABG' + dbnum + '_note';
        const abgabeart = 'ABGART' + dbnum;
        wasserunterverteilerSaveData[i] = {};
        wasserunterverteilerSaveData[i][label] =
          this.wasserunterverteilerPl[i].label;
        wasserunterverteilerSaveData[i][val1] = parseInt(
          this.wasserunterverteilerPl[i].value1.replaceAll('.', '')
        );
        wasserunterverteilerSaveData[i][val2] = parseInt(
          this.wasserunterverteilerPl[i].value2.replaceAll('.', '')
        );
        wasserunterverteilerSaveData[i][total] = parseInt(
          parseInt(this.wasserunterverteilerPl[i].sum.replaceAll('.', ''))
        );
        wasserunterverteilerSaveData[i][abgabeart] =
          this.wasserunterverteilerPl[i].abgabeart;
        wasserunterverteilerSaveData[i][note] =
          this.wasserunterverteilerPl[i].note;

        comments.push({
          key: label,
          value: this.wasserunterverteilerPl[i].labelComment,
        });
        comments.push({
          key: val1,
          value: this.wasserunterverteilerPl[i].value1Comment,
        });
        comments.push({
          key: val2,
          value: this.wasserunterverteilerPl[i].value2Comment,
        });
      }

      wasserunterverteilerSaveData.forEach((el) => {
        saveData = { ...saveData, ...el };
      });

      const einleitungOhneNutzungSaveData = this.getSingleRowSaveData(
        'einleitungOhneNutzung',
        'EINL'
      );
      comments.push({
        key: 'EINL_label',
        value: this.einleitungOhneNutzung.labelComment,
      });
      comments.push({
        key: 'EINL_2',
        value: this.einleitungOhneNutzung.value1Comment,
      });
      comments.push({
        key: 'EINL_1',
        value: this.einleitungOhneNutzung.value2Comment,
      });

      const bedarfSaveData = [];
      bedarfSaveData['BEDARF_2'] = this.calculateBedarf('value1');
      bedarfSaveData['BEDARF_1'] = this.calculateBedarf('value2');
      bedarfSaveData['BEDARF_G'] = this.calculateBedarf('sum');

      const haushaltungenSaveData = this.getSingleRowSaveData(
        'haushaltungen',
        'HAUSHALT'
      );

      comments.push({
        key: 'HAUSHALT_2',
        value: this.haushaltungen.value1Comment,
      });
      comments.push({
        key: 'HAUSHALT_1',
        value: this.haushaltungen.value2Comment,
      });
      const gewklSaveData = this.getSingleRowSaveData('gewkl', 'GEWKL');

      comments.push({
        key: 'GEWKL_2',
        value: this.gewkl.value1Comment,
      });
      comments.push({
        key: 'GEWKL_1',
        value: this.gewkl.value2Comment,
      });
      const gewgrSaveData = this.getSingleRowSaveData('gewgr', 'GEWGR');

      comments.push({
        key: 'GEWGR_2',
        value: this.gewgr.value1Comment,
      });
      comments.push({
        key: 'GEWGR_1',
        value: this.gewgr.value2Comment,
      });
      const oeffentlSaveData = this.getSingleRowSaveData(
        'oeffentl',
        'OEFFENTL'
      );

      comments.push({
        key: 'OEFFENTL_2',
        value: this.oeffentl.value1Comment,
      });
      comments.push({
        key: 'OEFFENTL_1',
        value: this.oeffentl.value2Comment,
      });
      const verlusteSaveData = this.getSingleRowSaveData(
        'verluste',
        'VERLUSTE'
      );

      comments.push({
        key: 'VERLUSTE_2',
        value: this.verluste.value1Comment,
      });
      comments.push({
        key: 'VERLUSTE_1',
        value: this.verluste.value2Comment,
      });
      const eigenvSaveData = this.getSingleRowSaveData('eigenv', 'EIGENV');

      comments.push({
        key: 'EIGENV_2',
        value: this.eigenv.value1Comment,
      });
      comments.push({
        key: 'EIGENV_1',
        value: this.eigenv.value2Comment,
      });

      let { eigengewinnung, eigengewinnungComments } =
        this.getEntnehmerAndFoerderData();
      comments.push(...eigengewinnungComments);

      const eigengewinnungOberflaeche = [];
      for (let i = 0; i < this.gewinnungsanlagenOberflaeche.length; i++) {
        // eigengewinnung oberflächenwasser
        eigengewinnungOberflaeche[i] = {};
        eigengewinnungOberflaeche[i]['FOEMENG_2'] = parseInt(
          this.gewinnungsanlagenOberflaeche[i].value1.replace(/[^\d]/g, '')
        );
        eigengewinnungOberflaeche[i]['FOEMENG_1'] = parseInt(
          this.gewinnungsanlagenOberflaeche[i].value2.replace(/[^\d]/g, '')
        );
        eigengewinnungOberflaeche[i]['FOEMENG_G'] = parseInt(
          this.gewinnungsanlagenOberflaeche[i].sum.replace(/[^\d]/g, '')
        );
        eigengewinnungOberflaeche[i]['note'] =
          this.gewinnungsanlagenOberflaeche[i].note;

        if (this.gewinnungsanlagenOberflaeche[i].label) {
          eigengewinnungOberflaeche[i]['OSNAME'] =
            this.gewinnungsanlagenOberflaeche[i].label;
        } else {
          eigengewinnungOberflaeche[i]['OSNAME'] = '';
        }
        eigengewinnungOberflaeche[i]['addedByUser'] =
          this.gewinnungsanlagenOberflaeche[i].addedByUser + '';
        eigengewinnungOberflaeche[i]['FOENR_GES'] =
          this.gewinnungsanlagenOberflaeche[i].FOENR_GES;
        if (!eigengewinnungOberflaeche[i]['FOENR_GES']) {
          eigengewinnungOberflaeche[i]['FOENR_GES'] = '';
        }
        // comments
        let eigengewinnungOberflaecheComments = [];
        const key1 = 'EIGEN_OBERFLAECHE' + i + '_OSNAME';
        const key2 = 'EIGEN_OBERFLAECHE' + i + '_FOEMENG_2';
        const key3 = 'EIGEN_OBERFLAECHE' + i + '_FOEMENG_1';

        if (this.gewinnungsanlagenOberflaeche[i].labelComment) {
          eigengewinnungOberflaecheComments.push({
            key: key1,
            value: this.gewinnungsanlagenOberflaeche[i].labelComment,
            label: this.gewinnungsanlagenOberflaeche[i].label,
          });
        }
        if (this.gewinnungsanlagenOberflaeche[i].value1Comment) {
          eigengewinnungOberflaecheComments.push({
            key: key2,
            value: this.gewinnungsanlagenOberflaeche[i].value1Comment,
            label: this.gewinnungsanlagenOberflaeche[i].label,
          });
        }
        if (this.gewinnungsanlagenOberflaeche[i].value2Comment) {
          eigengewinnungOberflaecheComments.push({
            key: key3,
            value: this.gewinnungsanlagenOberflaeche[i].value2Comment,
            label: this.gewinnungsanlagenOberflaeche[i].label,
          });
        }
        comments.push(...eigengewinnungOberflaecheComments);
      }

      const authToken = this.getAuthToken();

      const stampData = this.getStampDataToSend();

      saveData = {
        ...saveData,
        ...haushaltungenSaveData,
        ...gewklSaveData,
        ...gewgrSaveData,
        ...oeffentlSaveData,
        ...verlusteSaveData,
        ...eigenvSaveData,
        ...this.grundwasserfoerdermenge,
        ...this.wasseraufkommen,
        ...this.wasserbedarf,
        ...einleitungOhneNutzungSaveData,
        ...bedarfSaveData,
        EINL_note: this.einleitungOhneNutzung.EINL_note,
        EINL_label: this.einleitungOhneNutzung.EINL_label,
        EINL_KZ: this.einleitungOhneNutzung.EINL_KZ,
        HAUSHALT_note: this.haushaltungen.note,
        GEWKL_note: this.gewkl.note,
        GEWGR_note: this.gewgr.note,
        OEFFENTL_note: this.oeffentl.note,
        VERLUSTE_note: this.verluste.note,
        EIGENV_note: this.eigenv.note,
        allgemeineBemerkungen: this.allgemeineBemerkungen,
        wassergewinnungsrechte: this.wassergewinnungsrechte,
        versorgungsgebietsveraenderungen: this.versorgungsgebietsveraenderungen,
        eigengewinnung,
        eigengewinnungOberflaeche,
        comments,
        status: this.status,
        OEERFASSJ: this.year,
        lastchange: true,
        lastchange_user: true,
        JWT: authToken,
        ...stampData,
        hinweis_freigabe: this.internalNotes.hinweiseFreigebenden,
        hinweis_pruefung: this.internalNotes.hinweisePruefenden,
      };

      saveData = this.assemblePreviousData(
        saveData,
        'gewinnungsanlagenOberflaeche',
        'wasserlieferanten',
        this.fremdbezugMaxLength,
        'BEZM',
        'HERK',
        'BEZART',
        'wasserunterverteilerPl',
        this.fremdabgabeMaxLength,
        'ABG',
        'ZIEL',
        'ABGART'
      );

      return { saveData: saveData };
    },
    getEntnehmerAndFoerderData() {
      let eigengewinnung = [];
      let eigengewinnungComments = [];

      for (let i = 0; i < this.gewinnungsanlagen.length; i++) {
        // data for SO_FOERDER
        eigengewinnung[i] = {};
        eigengewinnung[i]['FOEMENG_2'] = parseInt(
          this.gewinnungsanlagen[i].value1.replace(/[^\d]/g, '')
        );
        eigengewinnung[i]['FOEMENG_1'] = parseInt(
          this.gewinnungsanlagen[i].value2.replace(/[^\d]/g, '')
        );
        eigengewinnung[i]['FOEMENG_G'] = parseInt(
          this.gewinnungsanlagen[i].sum.replace(/[^\d]/g, '')
        );
        if (
          this.gewinnungsanlagen[i].note == null ||
          !this.gewinnungsanlagen[i].note
        ) {
          eigengewinnung[i]['note'] = '';
        } else {
          eigengewinnung[i]['note'] = this.gewinnungsanlagen[i].note;
        }

        // data for SO_ENTNEHMER
        eigengewinnung[i]['OSNAME'] = this.gewinnungsanlagen[i].label;
        if (
          this.gewinnungsanlagen[i].addedByUser == true ||
          this.gewinnungsanlagen[i].addedByUser == 'true'
        ) {
          eigengewinnung[i]['addedByUser'] = true;
        } else if (
          this.gewinnungsanlagen[i].addedByUser == false ||
          this.gewinnungsanlagen[i].addedByUser == 'false'
        ) {
          eigengewinnung[i]['addedByUser'] = false;
        }
        eigengewinnung[i]['FOENR_GES'] = this.gewinnungsanlagen[i].FOENR_GES;
        if (!eigengewinnung[i]['FOENR_GES']) {
          eigengewinnung[i]['FOENR_GES'] = '';
        }

        // comments
        const key1 = 'EIGEN_' + i + '_OSNAME';
        const key2 = 'EIGEN_' + i + '_FOEMENG_2';
        const key3 = 'EIGEN_' + i + '_FOEMENG_1';

        eigengewinnungComments.push({
          key: key1,
          value: this.gewinnungsanlagen[i].labelComment,
        });
        eigengewinnungComments.push({
          key: key2,
          value: this.gewinnungsanlagen[i].value1Comment,
        });
        eigengewinnungComments.push({
          key: key3,
          value: this.gewinnungsanlagen[i].value2Comment,
        });
      }
      return {
        eigengewinnung: eigengewinnung,
        eigengewinnungComments: eigengewinnungComments,
      };
    },
    getSingleRowSaveData,
    sumValues,
    calculateValue,
    calculateBedarf,
    calculateSum(data, timespan) {
      let sum = 0;
      if (timespan === '2') {
        data.forEach((el) => {
          let val = el.value1.replaceAll('.', '');
          val = parseFloat(val);
          sum = sum + val;
        });
        if (data === this.gewinnungsanlagen) {
          this.grundwasserfoerdermenge.GESGW_2 = sum;
        }
      } else if (timespan === '1') {
        data.forEach((el) => {
          let val = el.value2.replaceAll('.', '');
          val = parseFloat(val);
          sum = sum + val;
        });
        if (data === this.gewinnungsanlagen) {
          this.grundwasserfoerdermenge.GESGW_1 = sum;
        }
      } else if (timespan === 'total') {
        data.forEach((el) => {
          let val1 = el.value1.replaceAll('.', '');
          let val2 = el.value2.replaceAll('.', '');
          let val = parseFloat(val1) + parseFloat(val2);
          sum = sum + val;
        });
        if (data === this.gewinnungsanlagen) {
          this.grundwasserfoerdermenge.GESGWG_G = sum;
        }
      }
      return sum;
    },
    calculateWaterOccurrence(timespan) {
      let sum = 0;
      if (timespan === '2') {
        sum = this.calculateSum(this.gewinnungsanlagen, '2');
        let waterDelivered = 0;
        this.wasserlieferanten.forEach((el) => {
          let val = el.value1.replaceAll('.', '');
          val = parseFloat(val);
          waterDelivered = waterDelivered + val;
        });
        sum = sum + waterDelivered;
        this.wasseraufkommen.AUFKOM_2 = sum;
      } else if (timespan === '1') {
        sum = this.calculateSum(this.gewinnungsanlagen, '1');
        let waterDelivered = 0;
        this.wasserlieferanten.forEach((el) => {
          let val = el.value2.replaceAll('.', '');
          val = parseFloat(val);
          waterDelivered = waterDelivered + val;
        });
        sum = sum + waterDelivered;
        this.wasseraufkommen.AUFKOM_1 = sum;
      } else if (timespan === 'total') {
        sum = this.calculateSum(this.gewinnungsanlagen, 'total');
        let waterDelivered = 0;
        this.wasserlieferanten.forEach((el) => {
          let val1 = el.value1.replaceAll('.', '');
          let val2 = el.value2.replaceAll('.', '');
          let val = parseFloat(val1) + parseFloat(val2);
          waterDelivered = waterDelivered + val;
        });
        sum = sum + waterDelivered;
        this.wasseraufkommen.AUFKOM_G = sum;
      }
      return sum;
    },
    add,
    remove,
    toNumber,
  },
};
</script>

<style lang="scss" scoped>
.relative {
  position: relative;
}

.icon-wrapper {
  position: absolute;
  left: -2.5rem;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.icon-wrapper > i {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
</style>
