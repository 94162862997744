import { getUserInfo } from '../utils/auth';
import ShortUniqueId from 'short-unique-id';
import axios from '../utils/api';

export default {
    currentDate() {
        const current = new Date();
        const date =
            current.getDate() +
            '.' +
            (current.getMonth() + 1) +
            '.' +
            current.getFullYear();
        return date;
    },
    add(items, addedByUser) {
        const fremdbezugName = 'wasserlieferanten';
        const fremdbezugMaxLength = this.fremdbezugMaxLength;
        const fremdabgabeName = this.formtype == 'IND' ? 'wasserempfaengerPl' : this.formtype == 'OEFF' ? 'wasserunterverteilerPl' : '';
        const fremdabgabeMaxLength = this.fremdabgabeMaxLength;
        const fremdbezugNameLy = 'wasserlieferantenLy';
        const fremdabgabeNameLy = fremdabgabeName.includes('Pl') ? fremdabgabeName.replace('Pl', '') + 'Ly' : fremdabgabeName + 'Ly';
        if (
            items === this[fremdbezugName] &&
            this[fremdbezugName].length >= fremdbezugMaxLength
        ) {
            this.$store.dispatch('setAlertShow', true);
            this.$store.dispatch('setAlertMsg', 'Maximale Anzahl an Fremdbezugfeldern erreicht');
            this.$store.dispatch('setAlertType', 'info');
            setTimeout(() => {
                this.$store.commit('setAlertShow', false);
            }, 3 * 1000);
        } else if (
            items === this.fremdabgabeName &&
            this.fremdabgabeName.length >= fremdabgabeMaxLength
        ) {
            this.$store.dispatch('setAlertShow', true);
            this.$store.dispatch('setAlertMsg', 'Maximale Anzahl an Feldern für Fremdabgabe ohne Nutzung erreicht');
            this.$store.dispatch('setAlertType', 'info');
            setTimeout(() => {
                this.$store.commit('setAlertShow', false);
            }, 3 * 1000);
        } else if (
            (items === this.gewinnungsanlagen ||
                items === this.gewinnungsanlagenOberflaeche || items == this.wasserlaeufe) &&
            addedByUser === true
        ) {
            const idGenerator = new ShortUniqueId({ length: 13 });
            const generatedId = idGenerator();
            console.log('generatedId: ', generatedId);
            items.push({
                label: '',
                value1: '0',
                value2: '0',
                sum: '0',
                note: '',
                id: Math.ceil(Math.random() * 1000000), //randomly generated id
                addedByUser: addedByUser,
                bemerkungMenu: false,
                labelCommentMenu: false,
                value1CommentMenu: false,
                value2CommentMenu: false,
                labelCommentOpened: false,
                value1CommentOpened: false,
                value2CommentOpened: false,
                FOENR_GES: generatedId,
            });
        } else if (items == this.gewinnungsanlagenLy || items == this.gewinnungsanlagenOberflaecheLy || items == this.wasserlaeufeLy) {
            items.push({
                OSNAME: '',
                FOEMENG_2: '0',
                FOEMENG_1: '0',
                FOEMENG_G: '0',
                note: '',
                addedByUser: addedByUser,
                OSRECHT_A: items == this.wasserlaeufeLy ? '0' : null,
            });
        }
        else {
            items.push({
                label: '',
                value1: '0',
                value2: '0',
                sum: '0',
                note: '',
                id: Math.ceil(Math.random() * 1000000), //randomly generated id
                addedByUser: addedByUser,
                labelCommentMenu: false,
                value1CommentMenu: false,
                value2CommentMenu: false,
                labelCommentOpened: false,
                value1CommentOpened: false,
                value2CommentOpened: false,
                bezugsart:
                    items === this[fremdbezugName] ||
                        items === this.newPreviousData.fremdbezugName
                        ? ''
                        : null,
                abgabeart:
                    items === this[fremdabgabeName] ||
                        items === this.fremdabgabeName ||
                        items === this.newPreviousData.fremdbezugName
                        ? ''
                        : null,
            });
        }
    },
    remove(items, item, lastYearsValues, name) {
        const index = items.indexOf(item);
        lastYearsValues.splice(index, 1);
        items.splice(index, 1);
        this.newPreviousData[name].splice(index, 1);
    },
    createGroups(arr, perGroup) {
        const numGroups = Math.ceil(arr.length / perGroup);
        return new Array(numGroups)
            .fill('')
            .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup));
    },

    sumValues(obj) {
        const result = Object.values(obj).reduce((sum, current) => {
            current = current + '';
            let val = current.replace(/[^\d]/g, '');
            isNaN(val) || val === '' ? val = 0 : null;
            return sum + parseInt(val);
        }, 0);
        return result;
    },
    formatDigitsOnly(event) {
        event.target.value = event.target.value.replace(/[^\d]/g, '');
        if (event.type === 'focusin') {
            setTimeout(() => {
                event.target.value == 0 ? event.target.value = '' : null;
            }, 0);
        }
    },
    formatDigitsOnlySingleRow(event) {
        event.target.value = event.target.value.replace(/[^\d]/g, '');
        if (event.type === 'focusin') {
            setTimeout(() => {
                event.target.value == 0 ? event.target.value = '' : null;
            }, 0);
        }
    },
    handleInput(ref, timespan) {
        const sum = this.calculateValue(ref);
        if (ref.sum || ref.sum === 0) {
            if (this.status === 'pruefung' && parseInt(ref.sum) !== sum) {
                ref.sumChanged = true;
            }
        }
        ref.sum = sum.toLocaleString('de-DE');
        let displayVal = ref[timespan].replace(/[^\d]/g, '');
        isNaN(displayVal) || displayVal === '' ? displayVal = 0 : null;
        displayVal = parseInt(displayVal).toLocaleString('de-DE');
        ref[timespan] = displayVal;
        this.$forceUpdate();
    },
    handleInputSingleRow(ref, key) {
        let displayVal = ref.inputs[key].replace(/[^\d]/g, '');
        isNaN(displayVal) || displayVal === '' ? displayVal = 0 : null;
        ref.inputs[key] = parseInt(displayVal).toLocaleString('de-DE');
        const sum = this.sumValues(ref.inputs);
        if (this.sumComponent || this.sumComponent === 0) {
            if (this.status === 'pruefung' && parseInt(this.sumComponent) !== sum) {
                this.data['sumChanged'] = true;
            }
        }
        this.$forceUpdate();
    },

    calculateValue(ref) {
        let val1 = ref.value1.replace(/[^\d]/g, '');
        let val2 = ref.value2.replace(/[^\d]/g, '');
        isNaN(val1) || val1 === '' ? val1 = 0 : null;
        isNaN(val2) || val2 === '' ? val2 = 0 : null;
        let sum = parseInt(val1) + parseInt(val2);
        return sum;
    },
    calculateBedarf(timespan, includeFremdabgabe) {
        let keypart = null;
        if (this.formtype === 'OEFF') {
            keypart = 'EINL';
        } else {
            keypart = 'EINLEIT';
        }
        let result = 0;
        let wasseraufkommen = 0;
        let val = 0;
        let einl = 0;
        let fremdabgabeName = '';
        if (this.formtype === 'OEFF') {
            fremdabgabeName = 'wasserunterverteilerPl';
        } else {
            fremdabgabeName = 'wasserempfaengerPl';
        }
        if (includeFremdabgabe) {
            let fremdabgabe = 0;
            this[fremdabgabeName].forEach((el) => {
                val = `${el[timespan]}`;
                val = val.replaceAll('.', '');
                fremdabgabe = fremdabgabe + parseInt(val);
            });
            if (timespan === 'value1') {
                wasseraufkommen = this.calculateWaterOccurrence('2');
                einl = this.einleitungOhneNutzung.inputs[(keypart + '_2')].replaceAll('.', '');
                einl = parseInt(einl);
                result = wasseraufkommen - fremdabgabe - einl;
                this.wasserbedarf.BEDARF_2 = result;
            } else if (timespan === 'value2') {
                wasseraufkommen = this.calculateWaterOccurrence('1');
                einl = this.einleitungOhneNutzung.inputs[(keypart + '_1')].replaceAll('.', '');
                einl = parseInt(einl);
                result = wasseraufkommen - fremdabgabe - einl;
                this.wasserbedarf.BEDARF_1 = result;
            } else if (timespan === 'sum') {
                wasseraufkommen = this.calculateWaterOccurrence('total');
                einl = parseInt(this[(keypart + '_G')].replaceAll('.', ''));
                result = wasseraufkommen - fremdabgabe - einl;
                this.wasserbedarf.BEDARF_G = result;
            }
        } else {
            if (timespan === 'value1') {
                wasseraufkommen = this.calculateWaterOccurrence('2');
                einl = this.einleitungOhneNutzung.inputs[(keypart + '_2')].replaceAll('.', '');
                einl = parseInt(einl);
                result = wasseraufkommen - einl;
                this.wasserbedarf.BEDARF_2 = result;
            } else if (timespan === 'value2') {
                wasseraufkommen = this.calculateWaterOccurrence('1');
                einl = this.einleitungOhneNutzung.inputs[(keypart + '_1')].replaceAll('.', '');
                einl = parseInt(einl);
                result = wasseraufkommen - einl;
                this.wasserbedarf.BEDARF_1 = result;
            } else if (timespan === 'sum') {
                wasseraufkommen = this.calculateWaterOccurrence('total');
                einl = parseInt(this[(keypart + '_G')].replaceAll('.', ''));
                result = wasseraufkommen - einl;
                this.wasserbedarf.BEDARF_G = result;
            }
        }
        return result;
    },
    async toDashboard(usertype) {
        const userInfo = await getUserInfo();
        if (userInfo) {
            const username = userInfo.ANSPRECH;
            if (usertype === 1) {
                if (this.$route.name !== 'dashboard_erft') {
                    this.$router.push({ name: 'dashboard_erft', params: { username: username } });
                }
            } else {
                if (this.$route.name !== 'dashboard') {
                    this.$router.push({ name: 'dashboard', params: { username: username } });
                }
            }
        } else {
            this.$router.push('/');
        }
    },
    toUserVerwaltung() {
        this.$router.push({ name: 'userverwaltung' });
    },
    toUserList() {
        this.$router.push({ name: 'userlist' });
    },
    toPasswordChange() {
        this.$router.push({ name: 'change_password' });
    },
    toNumber(string) {
        return parseInt(string.replace(/[^\d]/g, ''));
    },
    toggleBemerkung(item) {
        item.bemerkungMenu = !item.bemerkungMenu;
        this.$forceUpdate();
    },
    toggleMenu(item, prop) {
        item[prop] = !item[prop];
        const commentStr = prop.replace('Menu', 'Opened');
        item[commentStr] = true;
    },
    updateValue(fieldGroup, fieldName, val, subgroup) {
        if (subgroup || typeof subgroup == 'number') {
            this[fieldGroup][subgroup][fieldName] = val;
        } else {
            this[fieldGroup][fieldName] = val;
        }
    },
    checkCommentsOpened(arrayOfArrays, arrayOfSingles) {
        for (let i = 0; i < arrayOfArrays.length; i++) {
            for (let index = 0; index < arrayOfArrays[i].length; index++) {
                if (
                    (arrayOfArrays[i][index].labelComment &&
                        arrayOfArrays[i][index].labelComment !== '' &&
                        arrayOfArrays[i][index].labelCommentOpened === false) ||
                    (arrayOfArrays[i][index].value1Comment &&
                        arrayOfArrays[i][index].value1Comment !== '' &&
                        arrayOfArrays[i][index].value1CommentOpened === false) ||
                    (arrayOfArrays[i][index].value2Comment &&
                        arrayOfArrays[i][index].value2Comment !== '' &&
                        arrayOfArrays[i][index].value2CommentOpened === false)
                ) {
                    return false;
                }
            }
        }
        for (let i = 0; i < arrayOfSingles.length; i++) {
            if (
                (arrayOfSingles[i].labelComment &&
                    arrayOfSingles[i].labelComment !== '' &&
                    arrayOfSingles[i].labelCommentOpened === false) ||
                (arrayOfSingles[i].value1Comment &&
                    arrayOfSingles[i].value1Comment !== '' &&
                    arrayOfSingles[i].value1CommentOpened === false) ||
                (arrayOfSingles[i].value2Comment &&
                    arrayOfSingles[i].value2Comment !== '' &&
                    arrayOfSingles[i].value2CommentOpened === false)
            ) {
                return false;
            }
        }
        return true;
    },
    setDataLoaded(val) {
        this.dataLoaded = val;
    },
    handleScroll() {
        const fixedContainer = document.getElementById('fixed-container');
        const fixedRow = document.getElementById('fixed-row');
        const timespan = document.getElementById('timespan');
        const companyName = document.querySelector('.company');
        // const logoAppbar = document.getElementById('logo-appbar'); // logo removed for now
        const printing = this.$store.state.printing;
        if (printing == false) { // add printing condition to prevent readding 'sticky' due to automatic scroll on printing style changes
            if (window.scrollY > fixedContainer.offsetTop) {
                fixedRow.classList.add('sticky');
                timespan.classList.add('d-none');
                companyName ? companyName.classList.remove('d-flex') : null;
                companyName ? companyName.classList.add('d-none') : null;
                // logoAppbar.classList.add('d-none'); // logo removed for now
            } else {
                fixedRow.classList.remove('sticky');
                timespan.classList.remove('d-none');
                companyName ? companyName.classList.add('d-flex') : null;
                companyName ? companyName.classList.remove('d-none') : null;
                // logoAppbar.classList.remove('d-none'); // logo removed for now
            }
        }
    },
    readonly() {
        const userInfo = this.getUserInfo();
        const result =
            (this.usertype !== 1 && (this.status === 'pruefung' || this.status === 'erste_freigabe')) || (this.status === 'erste_freigabe' && this.freigeber_1 === this.usermail) ||
                this.status === 'freigegeben' ||
                (this.usertype === 1 && (this.status === 'ausstehend' || this.status === 'bearbeitung' || this.status === 'korrektur')) || (this.usertype == 1 && userInfo.nur_lesen === '1') || this.mode === 'first' || (this.year != this.currentYear())
                ? true
                : false;
        return result;
    },
    calculateSum(data, timespan) {
        let sum = 0;
        if (timespan === '2') {
            data.forEach((el) => {
                let val = el.value1.replaceAll('.', '');
                val = parseInt(val);
                sum = sum + val;
            });
            if (data === this.gewinnungsanlagen) {
                this.grundwasserfoerdermenge.MENGEGW_2 = sum;
            }
            if (data === this.gewinnungsanlagenOberflaeche) {
                this.oberflaechenwasserfoerdermenge.MENGEOW_2 = sum;
            }
        } else if (timespan === '1') {
            data.forEach((el) => {
                let val = el.value2.replaceAll('.', '');
                val = parseInt(val);
                sum = sum + val;
            });
            if (data === this.gewinnungsanlagen) {
                this.grundwasserfoerdermenge.MENGEGW_1 = sum;
            }
            if (data === this.gewinnungsanlagenOberflaeche) {
                this.oberflaechenwasserfoerdermenge.MENGEOW_1 = sum;
            }
        } else if (timespan === 'total') {
            data.forEach((el) => {
                let val1 = el.value1.replaceAll('.', '');
                let val2 = el.value2.replaceAll('.', '');
                let val = parseInt(val1) + parseInt(val2);
                sum = sum + val;
            });
            if (data === this.gewinnungsanlagen) {
                this.grundwasserfoerdermenge.MENGEGW_G = sum;
            }
            if (data === this.gewinnungsanlagenOberflaeche) {
                this.oberflaechenwasserfoerdermenge.MENGEOW_G = sum;
            }
        }
        return sum;
    },
    calculateWaterOccurrence(timespan) {
        let sum = 0;
        if (timespan === '2') {
            sum =
                this.calculateSum(this.gewinnungsanlagen, '2') +
                this.calculateSum(this.gewinnungsanlagenOberflaeche, '2');
            let waterDelivered = 0;
            this.wasserlieferanten.forEach((el) => {
                let val = el.value1.replaceAll('.', '');
                val = parseInt(val);
                waterDelivered = waterDelivered + val;
            });
            sum = sum + waterDelivered;
            this.wasseraufkommen.wasseraufkommen_2 = sum;
        } else if (timespan === '1') {
            sum =
                this.calculateSum(this.gewinnungsanlagen, '1') +
                this.calculateSum(this.gewinnungsanlagenOberflaeche, '1');
            let waterDelivered = 0;
            this.wasserlieferanten.forEach((el) => {
                let val = el.value2.replaceAll('.', '');
                val = parseInt(val);
                waterDelivered = waterDelivered + val;
            });
            sum = sum + waterDelivered;
            this.wasseraufkommen.wasseraufkommen_1 = sum;
        } else if (timespan === 'total') {
            sum =
                this.calculateSum(this.gewinnungsanlagen, 'total') +
                this.calculateSum(this.gewinnungsanlagenOberflaeche, 'total');
            let waterDelivered = 0;
            this.wasserlieferanten.forEach((el) => {
                let val1 = el.value1.replaceAll('.', '');
                let val2 = el.value2.replaceAll('.', '');
                let val = parseInt(val1) + parseInt(val2);
                waterDelivered = waterDelivered + val;
            });
            sum = sum + waterDelivered;
            this.wasseraufkommen.wasseraufkommen_G = sum;
        }
        return sum;
    },
    calculateWaterOccurrenceLy(timespan) {
        let sum = 0;
        if (timespan === '2') {
            let gewinnungsanlagenLySum = 0;
            this.gewinnungsanlagenLy.forEach((el) => {
                let val = el.FOEMENG_2.replaceAll('.', '');
                val = parseInt(val);
                gewinnungsanlagenLySum = gewinnungsanlagenLySum + val;
            });
            let gewinnungsanlagenOberflaecheLySum = 0;
            this.gewinnungsanlagenOberflaecheLy.forEach((el) => {
                let val = el.FOEMENG_2.replaceAll('.', '');
                val = parseInt(val);
                gewinnungsanlagenOberflaecheLySum =
                    gewinnungsanlagenOberflaecheLySum + val;
            });
            sum = gewinnungsanlagenLySum + gewinnungsanlagenOberflaecheLySum;
            let waterDelivered = 0;
            for (let index = 0; index < this.wasserlieferantenLy.length; index++) {
                const el = this.wasserlieferantenLy[index];
                let val = el.value1.replaceAll('.', '');
                val = parseInt(val);
                waterDelivered = waterDelivered + val;
            }
            sum = sum + waterDelivered;
        } else if (timespan === '1') {
            let gewinnungsanlagenLySum = 0;
            this.gewinnungsanlagenLy.forEach((el) => {
                let val = el.FOEMENG_1.replaceAll('.', '');
                val = parseInt(val);
                gewinnungsanlagenLySum = gewinnungsanlagenLySum + val;
            });
            let gewinnungsanlagenOberflaecheLySum = 0;
            this.gewinnungsanlagenOberflaecheLy.forEach((el) => {
                let val = el.FOEMENG_1.replaceAll('.', '');
                val = parseInt(val);
                gewinnungsanlagenOberflaecheLySum =
                    gewinnungsanlagenOberflaecheLySum + val;
            });
            sum = gewinnungsanlagenLySum + gewinnungsanlagenOberflaecheLySum;
            let waterDelivered = 0;
            for (let index = 0; index < this.wasserlieferantenLy.length; index++) {
                const el = this.wasserlieferantenLy[index];
                let val = el.value2.replaceAll('.', '');
                val = parseInt(val);
                waterDelivered = waterDelivered + val;
            }
            sum = sum + waterDelivered;
        } else if (timespan === 'total') {
            let gewinnungsanlagenLySum = 0;
            this.gewinnungsanlagenLy.forEach((el) => {
                let val = el.FOEMENG_G.replaceAll('.', '');
                val = parseInt(val);
                gewinnungsanlagenLySum = gewinnungsanlagenLySum + val;
            });
            let gewinnungsanlagenOberflaecheLySum = 0;
            this.gewinnungsanlagenOberflaecheLy.forEach((el) => {
                let val = el.FOEMENG_G.replaceAll('.', '');
                val = parseInt(val);
                gewinnungsanlagenOberflaecheLySum =
                    gewinnungsanlagenOberflaecheLySum + val;
            });
            sum = gewinnungsanlagenLySum + gewinnungsanlagenOberflaecheLySum;
            let waterDelivered = 0;
            for (let index = 0; index < this.wasserlieferantenLy.length; index++) {
                const el = this.wasserlieferantenLy[index];
                let val = el.sum.replaceAll('.', '');
                val = parseInt(val);
                waterDelivered = waterDelivered + val;
            }
            sum = sum + waterDelivered;
        }
        return sum.toLocaleString('de-DE');
    },
    customSort(items, index, isDesc) {
        items.sort((a, b) => {
            if (index[0] == 'lastchange') {
                if (!isDesc[0]) {
                    return a.timestamp - b.timestamp;
                } else {
                    return b.timestamp - a.timestamp;
                }
            } else if (index[0] == 'mailSent') {
                if (!isDesc[0]) {
                    return a.mailTimestamp - b.mailTimestamp;
                } else {
                    return b.mailTimestamp - a.mailTimestamp;
                }
            } else if (index[0] == 'inputReceived') {
                if (!isDesc[0]) {
                    return a[index[0]] ? -1 : 1; // `true` values first
                } else {
                    return a[index[0]] ? 1 : -1; // `false` values first
                }
            } else if (!isNaN(a[index[0]])) {
                if (!isDesc[0]) {
                    return a[index[0]] - b[index[0]];
                } else {
                    return b[index[0]] - a[index[0]];
                }
            } else {
                if (!isDesc[0]) {
                    return a[index[0]] < b[index[0]] ? -1 : 1;
                } else {
                    return b[index[0]] < a[index[0]] ? -1 : 1;
                }
            }
        });
        return items;
    },
    buildDashboard(data) {
        // empty forms
        this.forms = [];
        data.forEach((el) => {
            let firmaCommaBetrieb = '';
            el.FIRMA1 ? firmaCommaBetrieb += el.FIRMA1 : null;
            el.FIRMA2 ? firmaCommaBetrieb += ', ' + el.FIRMA2 : null;
            el.FIRMA3 ? firmaCommaBetrieb += ', ' + el.FIRMA3 : null;
            el.BETRIEB1 ? firmaCommaBetrieb += ', ' + el.BETRIEB1 : null;
            let cat = '';
            switch (el.FRAGEB_NR) {
                case '1':
                    cat = 'Öffentliche Wasserversorgung';
                    break;
                case '2':
                    cat = 'Gewerbliche Betriebe';
                    break;
                case '3':
                    cat = 'Kies- und Betonwerke';
                    break;
                case '4':
                    cat = 'Sport-/Freizeitanlagen';
                    break;
                case '5':
                    cat = 'Landwirtschaft';
                    break;
                default:
                    break;
            }
            let address = '';
            if (el.ANSPRECH_ANREDE && el.ANSPRECH) {
                address = el.ANSPRECH_ANREDE + ' ' + el.ANSPRECH;
            } else if (!el.ANSPRECH_ANREDE && el.ANSPRECH) {
                address = el.ANSPRECH;
            }
            const formattedDatetime = new Date(el.lastchange).toLocaleString(
                'de-DE'
            );
            const timestamp = Date.parse(el.lastchange) / 1000;
            const formattedStatus = (
                el.status.charAt(0).toUpperCase() + el.status.slice(1)
            ).replace('ue', 'ü').replace('_', ' ').replace('freigabe', 'Freigabe');
            let mailHistory = el.mailhistory;
            mailHistory = mailHistory.replaceAll(/(\d{4})-(\d{1,2})-(\d{1,2})/g, '$3.$2.$1'); // format dates
            let mailHistoryTable = el.mailhistory.replaceAll(/<([\/])*?ul>/g, '<$1table>');
            mailHistoryTable = mailHistoryTable.replaceAll(/<([\/])*?li>/g, '<$1tr>');
            mailHistoryTable = mailHistoryTable.replaceAll(/(<tr>)/g, '$1<td>');
            mailHistoryTable = mailHistoryTable.replaceAll(/(<\/tr>)/g, '</td>$1');
            mailHistoryTable = mailHistoryTable.replaceAll(/<td>,/g, '<td>');
            mailHistoryTable = mailHistoryTable.replaceAll(/(\d{4})-(\d{1,2})-(\d{1,2})/g, '$3.$2.$1'); // format dates

            let approvalHistory = '';
            let approvalHistoryTable = '';
            if (el.user_korrektur && el.user_korrektur !== '') {
                if (el.user_korrektur === null) {
                    el.user_korrektur = '';
                }
                let date = el.datum_korrektur.replaceAll(/(\d{4})-(\d{2})-(\d{2}).*/g, '$3.$2.$1');
                let time = el.datum_korrektur.match(/\d{2}:\d{2}:\d{2}/);
                approvalHistory += '<ul><li>Daten eingegeben von ' + el.user_korrektur + (date ? ' am ' + date : '') + (time ? ' um ' + time : '') + '</li>';
                approvalHistoryTable += '<table><tr><td>Daten eingegeben von ' + el.user_korrektur + (date ? ' am ' + date : '') + (time ? ' um ' + time : '') + '</td></tr>';
            }
            if (el.freigeber_1 !== null && el.freigeber_1 !== '') {
                if (el.datum_freigabe_1 === null) {
                    el.datum_freigabe_1 = '';
                }
                let date = el.datum_freigabe_1.replaceAll(/(\d{4})-(\d{2})-(\d{2}).*/g, '$3.$2.$1');
                let time = el.datum_freigabe_1.match(/\d{2}:\d{2}:\d{2}/);
                approvalHistory += '<li>Erste Freigabe von ' + el.freigeber_1 + (date ? ' am ' + date : '') + (time ? ' um ' + time : '') + '</li>';
                approvalHistoryTable += '<tr><td>Erste Freigabe von ' + el.freigeber_1 + (date ? ' am ' + date : '') + (time ? ' um ' + time : '') + '</td></tr>';
            }
            if (el.freigeber_2 !== null && el.freigeber_2 !== '') {
                if (el.datum_freigabe_2 === null) {
                    el.datum_freigabe_2 = '';
                }
                let date = el.datum_freigabe_2.replaceAll(/(\d{4})-(\d{2})-(\d{2}).*/g, '$3.$2.$1');
                let time = el.datum_freigabe_2.match(/\d{2}:\d{2}:\d{2}/);
                approvalHistory += '<li>Zweite Freigabe von ' + el.freigeber_2 + (date ? ' am ' + date : '') + (time ? ' um ' + time : '') + '</li>';
                approvalHistoryTable += '<tr><td>Zweite Freigabe von ' + el.freigeber_2 + (date ? ' am ' + date : '') + (time ? ' um ' + time : '') + '</td></tr>';
            }
            if (approvalHistory !== '') {
                approvalHistory += '</ul>';
                approvalHistoryTable += '</table>';
            }
            const inputReceived = el.eingangskontrolle == '1' ? true : el.eingangskontrolle == '0' ? false : null;

            const newFormObj = {
                mitglied: decodeURI(el.MIMITGL),
                address: address,
                year: el.BEFRAG,
                name: firmaCommaBetrieb,
                category: cat,
                mail: el.MAIL,
                lastchange: formattedDatetime,
                timestamp: timestamp,
                lastchangeuser: el.lastchange_user,
                status: formattedStatus,
                mailHistory: mailHistory,
                mailHistoryTable: mailHistoryTable,
                approvalHistory: approvalHistory,
                approvalHistoryTable: approvalHistoryTable,
                // mailTimestamp: mailTimestamp,
                userBtn: decodeURI(el.MIMITGL),
                inputReceived: inputReceived
            };
            this.forms.push(newFormObj);
        });
    },
    generateTimestamp() {
        const random = Math.floor(Math.random() * 10000000);
        const timestamp = Math.floor(Date.now());
        return timestamp + random;
    },
    handleRowClick(item) {
        this.$store.commit('setFirmaCommaBetrieb', item.name);
        this.$store.commit('setMitglied', item.mitglied);
        let name = '';
        switch (item.category) {
            case 'Öffentliche Wasserversorgung':
                name = 'form_oeff';
                break;
            case 'Gewerbliche Betriebe':
                name = 'form_gew';
                break;
            case 'Kies- und Betonwerke':
                name = 'form_kibe';
                break;
            case 'Sport-/Freizeitanlagen':
                name = 'form_spofr';
                break;
            case 'Landwirtschaft':
                name = 'form_land';
                break;
            default:
                break;
        }
        if (name === 'form_spofr' && item.mitglied.startsWith('5')) {
            this.$router.push({
                name: name + '_land',
                params: { username: item.mitglied, year: item.year },
            });
        } else {
            this.$router.push({
                name: name,
                params: { username: item.mitglied, year: item.year },
            });
        }
    },
    currentYear() {
        const date = new Date();
        let year = date.getFullYear();
        const month = date.getMonth();
        month < 5 ? year = year - 1 : null;
        return year;
    },
    getRelativeDifference(num1, num2) {
        if (typeof (num1) !== 'number') {
            num1 = parseInt(num1.replaceAll('.', ''));
        }
        if (typeof (num2) !== 'number') {
            num2 = parseInt(num2.replaceAll('.', ''));
        }
        let dif = 0;
        let result = 0;
        if (num1 > num2) {
            dif = num1 - num2;
            result = (dif / num2) * 100;
        } else {
            dif = num2 - num1;
            result = (dif / num1) * 100;
        }
        return result;
    },
    toUser(item) {
        this.$router.push({
            name: 'userpage',
            params: { userno: item.MIMITGL },
        });
    },
    beforeLoadForm() {
        const userInfo = this.getUserInfo();
        if (userInfo.erste_freigabe === '1') {
            this.erste_freigabe = true;
        } else if (userInfo.erste_freigabe === '0') {
            this.erste_freigabe = false;
        }
        if (userInfo.zweite_freigabe === '1') {
            this.zweite_freigabe = true;
        } else if (userInfo.zweite_freigabe === '0') {
            this.zweite_freigabe = false;
        }
        this.$store.dispatch('setUsermail', userInfo.MAIL);
    },
    getFreigeber(saveData) {
        this.freigeber_1 = saveData.freigeber_1;
        this.freigeber_2 = saveData.freigeber_2;
        this.datum_freigabe_1 = saveData.datum_freigabe_1;
        this.datum_freigabe_2 = saveData.datum_freigabe_2;
        if (saveData.datum_freigabe_1 && saveData.datum_freigabe_1 !== '' && saveData.datum_freigabe_1 !== null) {
            this.datum_freigabe_1 = saveData.datum_freigabe_1.replaceAll(/(\d{4})-(\d{2})-(\d{2}).*/g, '$3.$2.$1');
            this.zeit_freigabe_1 = saveData.datum_freigabe_1.match(/\d{2}:\d{2}:\d{2}/);
        }
        if (saveData.datum_freigabe_2 && saveData.datum_freigabe_2 !== '' && saveData.datum_freigabe_2 !== null) {
            this.datum_freigabe_2 = saveData.datum_freigabe_2.replaceAll(/(\d{4})-(\d{2})-(\d{2}).*/g, '$3.$2.$1');
            this.zeit_freigabe_2 = saveData.datum_freigabe_2.match(/\d{2}:\d{2}:\d{2}/);
        }
    },
    getNote(group, keypart) {
        const noteName = keypart + '_note';
        if (this.saveData[noteName]) {
            this[group].note = this.saveData[noteName];
        }
    },
    getEigengewinnung(formLand) {
        let longestFoerder = 0;
        if (this.saveData) {
            longestFoerder = Math.max(
                this.saveData.eigengewinnung.length,
                this.gewinnungsanlagenLy.length
            );
        } else if (!this.saveData) {
            longestFoerder = this.gewinnungsanlagenLy.length;
        }

        for (let i = 0; i < longestFoerder; i++) {
            if (this.saveData && this.saveData.eigengewinnung[i]) {
                const ObjWStringifiedVals = {};
                ObjWStringifiedVals.label = this.saveData.eigengewinnung[i].OSNAME;
                ObjWStringifiedVals.value1 = parseInt(
                    this.saveData.eigengewinnung[i].FOEMENG_2
                ).toLocaleString('de-DE');
                ObjWStringifiedVals.value2 = parseInt(
                    this.saveData.eigengewinnung[i].FOEMENG_1
                ).toLocaleString('de-DE');
                if (formLand) {
                    ObjWStringifiedVals.rights = parseInt(
                        this.saveData.eigengewinnung[i].OSRECHT_A
                    ).toLocaleString('de-DE');
                }
                ObjWStringifiedVals.OSSTOCKWERK = this.saveData.eigengewinnung[i].OSSTOCKWERK ? parseInt(this.saveData.eigengewinnung[i].OSSTOCKWERK).toLocaleString('de-DE') : undefined;
                ObjWStringifiedVals.OSHORIZONT = this.saveData.eigengewinnung[i].OSHORIZONT ? this.saveData.eigengewinnung[i].OSHORIZONT : undefined;

                if (this.saveData.previousData && this.saveData.previousData.eigengewinnung) {
                    const matchInPrevData = this.saveData.previousData ? this.saveData.previousData.eigengewinnung.find((el) => el.FOENR_GES == this.saveData.eigengewinnung[i].FOENR_GES) : undefined;
                    if (matchInPrevData) {
                        const gewinnungsanlagenLyObj = {};
                        gewinnungsanlagenLyObj.OSNAME = matchInPrevData.OSNAME;
                        gewinnungsanlagenLyObj.FOEMENG_2 = parseInt(
                            matchInPrevData.FOEMENG_2
                        ).toLocaleString('de-DE');
                        gewinnungsanlagenLyObj.FOEMENG_1 = parseInt(
                            matchInPrevData.FOEMENG_1
                        ).toLocaleString('de-DE');
                        gewinnungsanlagenLyObj.FOEMENG_G = parseInt(
                            matchInPrevData.FOEMENG_G
                        ).toLocaleString('de-DE');
                        gewinnungsanlagenLyObj.note =
                            matchInPrevData.note;
                        if (gewinnungsanlagenLyObj.note === null) {
                            gewinnungsanlagenLyObj.note = '';
                        }
                        gewinnungsanlagenLyObj.OSSTOCKWERK = parseInt(matchInPrevData.OSSTOCKWERK).toLocaleString('de-DE');
                        gewinnungsanlagenLyObj.OSHORIZONT = matchInPrevData.OSHORIZONT;
                        this.gewinnungsanlagenLy[i] = gewinnungsanlagenLyObj;
                    } else {
                        const gewinnungsanlagenLyObj = {};
                        gewinnungsanlagenLyObj.OSNAME = '';
                        gewinnungsanlagenLyObj.FOEMENG_2 = '0';
                        gewinnungsanlagenLyObj.FOEMENG_1 = '0';
                        gewinnungsanlagenLyObj.FOEMENG_G = '0';
                        gewinnungsanlagenLyObj.note = '';
                        gewinnungsanlagenLyObj.OSSTOCKWERK = '0';
                        gewinnungsanlagenLyObj.OSHORIZONT = '0';
                        this.gewinnungsanlagenLy[i] = gewinnungsanlagenLyObj;
                    }
                    if (
                        this.saveData.previousData &&
                        this.status !== 'bearbeitung' &&
                        this.saveData.eigengewinnung[i] &&
                        matchInPrevData
                    ) {
                        if (this.saveData.eigengewinnung[i].OSNAME &&
                            matchInPrevData.OSNAME &&
                            this.saveData.eigengewinnung[i].OSNAME !=
                            matchInPrevData.OSNAME) {
                            ObjWStringifiedVals.labelDif = 'dif';
                        }
                        if (
                            this.saveData.eigengewinnung[i].FOEMENG_2 &&
                            matchInPrevData.FOEMENG_2 &&
                            this.saveData.eigengewinnung[i].FOEMENG_2 !=
                            matchInPrevData.FOEMENG_2
                        ) {
                            ObjWStringifiedVals.value1Dif = 'dif';
                        }
                        if (
                            this.saveData.eigengewinnung[i].FOEMENG_1 &&
                            matchInPrevData.FOEMENG_1 &&
                            this.saveData.eigengewinnung[i].FOEMENG_1 !=
                            matchInPrevData.FOEMENG_1
                        ) {
                            ObjWStringifiedVals.value2Dif = 'dif';
                        }
                    }
                }


                ObjWStringifiedVals.sum = parseInt(
                    this.saveData.eigengewinnung[i].FOEMENG_G
                ).toLocaleString('de-DE');
                ObjWStringifiedVals.note = this.saveData.eigengewinnung[i].note;
                if (ObjWStringifiedVals.note === null) {
                    ObjWStringifiedVals.note = '';
                }
                ObjWStringifiedVals.bemerkungMenu = false;
                ObjWStringifiedVals.labelCommentMenu = false;
                ObjWStringifiedVals.value1CommentMenu = false;
                ObjWStringifiedVals.value2CommentMenu = false;
                ObjWStringifiedVals.labelCommentOpened = false;
                ObjWStringifiedVals.value1CommentOpened = false;
                ObjWStringifiedVals.value2CommentOpened = false;
                ObjWStringifiedVals.FOENR_GES = this.saveData.eigengewinnung[i].FOENR_GES;
                if (
                    this.saveData.eigengewinnung[i].addedByUser === false ||
                    this.saveData.eigengewinnung[i].addedByUser === 'false'
                ) {
                    ObjWStringifiedVals.addedByUser = false;
                } else {
                    ObjWStringifiedVals.addedByUser = true;
                }

                this.gewinnungsanlagen[i] = ObjWStringifiedVals;
            }
        }
        if (this.gewinnungsanlagen.length < 1) {
            this.add(this.gewinnungsanlagen, true);
        }
        while (this.gewinnungsanlagen.length > this.gewinnungsanlagenLy.length) {
            this.add(this.gewinnungsanlagenLy, true);
        }
    },
    getEigengewinnungComments() {
        let longestFoerder = 0;
        if (this.saveData) {
            longestFoerder = Math.max(
                this.saveData.eigengewinnung.length,
                this.gewinnungsanlagenLy.length
            );
        } else if (!this.saveData) {
            longestFoerder = this.gewinnungsanlagenLy.length;
        }
        if (this.saveData.comments) {
            const labelComments = this.saveData.comments.filter((item) => (item.key.includes('EIGEN') && item.key.includes('_OSNAME')) && !item.key.includes('OBERFLAECHE'));
            const value1Comments = this.saveData.comments.filter((item) => (item.key.includes('EIGEN') && item.key.includes('_FOEMENG_2')) && !item.key.includes('OBERFLAECHE'));
            const value2Comments = this.saveData.comments.filter((item) => (item.key.includes('EIGEN') && item.key.includes('_FOEMENG_1')) && !item.key.includes('OBERFLAECHE'));
            for (let i = 0; i < this.gewinnungsanlagen.length; i++) {
                const matchingLabelComment = labelComments.find((item) => (item.label && item.label == this.gewinnungsanlagen[i].label));
                this.gewinnungsanlagen[i].labelComment = matchingLabelComment ? matchingLabelComment.value : '';
                const matchingValue1Comment = value1Comments.find((item) => (item.label && item.label == this.gewinnungsanlagen[i].label));
                this.gewinnungsanlagen[i].value1Comment = matchingValue1Comment ? matchingValue1Comment.value : '';
                const matchingValue2Comment = value2Comments.find((item) => (item.label && item.label == this.gewinnungsanlagen[i].label));
                this.gewinnungsanlagen[i].value2Comment = matchingValue2Comment ? matchingValue2Comment.value : '';
            }
        }
    },
    multisort(arrToSortBy, field, otherArrays) {
        var keys = [], i, tmpKeys;
        arrToSortBy.sort(function (a, b) {
            if (field) {
                var ret = (a[field] > b[field]) ? 1 : (a[field] < b[field]) ? -1 : 0;
            } else {
                var ret = (a > b) ? 1 : (a < b) ? -1 : 0;
            }
            // storing the return values to be used for the other arrays
            keys.push(ret);
            return ret;
        });
        for (i = 0; i < otherArrays.length; i++) {
            // copy the stored retun values
            tmpKeys = keys.concat([]);
            otherArrays[i].sort(function () {
                // return the saved values based on arrToSortBy array's sort
                return tmpKeys.splice(0, 1);
            });
        }
    },
    getEigengewinnungOberflaeche(name) {
        const nameLy = name + 'Ly';

        let longestFoerderOberflaeche = 0;
        if (this.saveData) {
            longestFoerderOberflaeche = Math.max(
                this.saveData.eigengewinnungOberflaeche.length,
                this[nameLy].length
            );
        } else if (!this.saveData) {
            longestFoerderOberflaeche = this[nameLy].length;
        }

        for (let i = 0; i < longestFoerderOberflaeche; i++) {
            if (this.saveData && this.saveData.eigengewinnungOberflaeche[i]) {
                const ObjWStringifiedVals = {};
                ObjWStringifiedVals.label = this.saveData.eigengewinnungOberflaeche[i].OSNAME;
                ObjWStringifiedVals.value1 = parseInt(
                    this.saveData.eigengewinnungOberflaeche[i].FOEMENG_2
                ).toLocaleString('de-DE');
                ObjWStringifiedVals.value2 = parseInt(
                    this.saveData.eigengewinnungOberflaeche[i].FOEMENG_1
                ).toLocaleString('de-DE');

                const matchInPrevData = this.saveData.previousData ? this.saveData.previousData.eigengewinnungOberflaeche.find((el) => el.FOENR_GES == this.saveData.eigengewinnungOberflaeche[i].FOENR_GES) : undefined;
                if (matchInPrevData) {
                    const gewinnungsanlagenOberflaecheLyObj = {};
                    gewinnungsanlagenOberflaecheLyObj.OSNAME = matchInPrevData.OSNAME;
                    gewinnungsanlagenOberflaecheLyObj.FOEMENG_2 = parseInt(
                        matchInPrevData.FOEMENG_2
                    ).toLocaleString('de-DE');
                    gewinnungsanlagenOberflaecheLyObj.FOEMENG_1 = parseInt(
                        matchInPrevData.FOEMENG_1
                    ).toLocaleString('de-DE');
                    gewinnungsanlagenOberflaecheLyObj.FOEMENG_G = parseInt(
                        matchInPrevData.FOEMENG_G
                    ).toLocaleString('de-DE');
                    gewinnungsanlagenOberflaecheLyObj.note =
                        matchInPrevData.note;
                    if (gewinnungsanlagenOberflaecheLyObj.note === null) {
                        gewinnungsanlagenOberflaecheLyObj.note = '';
                    }
                    this[nameLy][i] = gewinnungsanlagenOberflaecheLyObj;
                } else {
                    const gewinnungsanlagenOberflaecheLyObj = {};
                    gewinnungsanlagenOberflaecheLyObj.OSNAME = '';
                    gewinnungsanlagenOberflaecheLyObj.FOEMENG_2 = '0';
                    gewinnungsanlagenOberflaecheLyObj.FOEMENG_1 = '0';
                    gewinnungsanlagenOberflaecheLyObj.FOEMENG_G = '0';
                    gewinnungsanlagenOberflaecheLyObj.note = '';
                    this[nameLy][i] = gewinnungsanlagenOberflaecheLyObj;
                }

                if (
                    this.saveData.previousData &&
                    this.status !== 'bearbeitung' &&
                    this.saveData.eigengewinnungOberflaeche[i] &&
                    matchInPrevData
                ) {
                    if (this.saveData.eigengewinnungOberflaeche[i].OSNAME &&
                        matchInPrevData.OSNAME &&
                        this.saveData.eigengewinnungOberflaeche[i].OSNAME !=
                        matchInPrevData.OSNAME) {
                        ObjWStringifiedVals.labelDif = 'dif';
                    }
                    if (
                        this.saveData.eigengewinnungOberflaeche[i].FOEMENG_2 &&
                        matchInPrevData
                            .FOEMENG_2 &&
                        this.saveData.eigengewinnungOberflaeche[i].FOEMENG_2 !=
                        matchInPrevData
                            .FOEMENG_2
                    ) {
                        ObjWStringifiedVals.value1Dif = 'dif';
                    }
                    if (
                        this.saveData.eigengewinnungOberflaeche[i].FOEMENG_1 &&
                        matchInPrevData
                            .FOEMENG_1 &&
                        this.saveData.eigengewinnungOberflaeche[i].FOEMENG_1 !=
                        matchInPrevData
                            .FOEMENG_1
                    ) {
                        ObjWStringifiedVals.value2Dif = 'dif';
                    }
                }
                ObjWStringifiedVals.sum = parseInt(
                    this.saveData.eigengewinnungOberflaeche[i].FOEMENG_G
                ).toLocaleString('de-DE');
                if (this.saveData.eigengewinnungOberflaeche[i].note === null) {
                    ObjWStringifiedVals.note = '';
                } else {
                    ObjWStringifiedVals.note =
                        this.saveData.eigengewinnungOberflaeche[i].note;
                }
                ObjWStringifiedVals.FOENR_GES =
                    this.saveData.eigengewinnungOberflaeche[i].FOENR_GES;
                if (
                    this.saveData.eigengewinnungOberflaeche[i].addedByUser === false ||
                    this.saveData.eigengewinnungOberflaeche[i].addedByUser === 'false'
                ) {
                    ObjWStringifiedVals.addedByUser = false;
                } else {
                    ObjWStringifiedVals.addedByUser = true;
                }
                this[name][i] = ObjWStringifiedVals;
            }
        }
    },
    getEigengewinnungOberflaecheComments(name) {
        const nameLy = name + 'Ly';
        let longestFoerderOberflaeche = 0;
        if (this.saveData) {
            longestFoerderOberflaeche = Math.max(
                this.saveData.eigengewinnungOberflaeche.length,
                this[nameLy].length
            );
        } else if (!this.saveData) {
            longestFoerderOberflaeche = this[nameLy].length;
        }
        if (this.saveData.comments) {
            const labelComments = this.saveData.comments.filter((item) => (item.key.includes('EIGEN_OBERFLAECHE') && item.key.includes('_OSNAME')));
            const value1Comments = this.saveData.comments.filter((item) => (item.key.includes('EIGEN_OBERFLAECHE') && item.key.includes('_FOEMENG_2')));
            const value2Comments = this.saveData.comments.filter((item) => (item.key.includes('EIGEN_OBERFLAECHE') && item.key.includes('_FOEMENG_1')));
            for (let i = 0; i < this[name].length; i++) {
                const matchingLabelComment = labelComments.find((item) => (item.label && item.label == this[name][i].label));
                this[name][i].labelComment = matchingLabelComment ? matchingLabelComment.value : '';
                const matchingValue1Comment = value1Comments.find((item) => (item.label && item.label == this[name][i].label));
                this[name][i].value1Comment = matchingValue1Comment ? matchingValue1Comment.value : '';
                const matchingValue2Comment = value2Comments.find((item) => (item.label && item.label == this[name][i].label));
                this[name][i].value2Comment = matchingValue2Comment ? matchingValue2Comment.value : '';
            }
        }
    },
    getFremdbezug(namepartVal, namepartOrigin, originVariant) {
        let wasserlieferantenArray = [];
        let wasserlieferantenArrayPrevious = [];

        if (this.saveData) {
            wasserlieferantenArray = Object.entries(this.saveData).filter(([key]) =>
                key.includes(namepartVal)
            );
        }

        if (this.status !== 'bearbeitung' && this.saveData.previousData) {
            wasserlieferantenArrayPrevious = Object.entries(
                this.saveData.previousData
            ).filter(([key]) => key.includes(namepartVal));
        }

        const wasserlieferantenLyGrouped = this.createGroups(
            wasserlieferantenArrayPrevious,
            4
        );
        for (let i = 0; i < wasserlieferantenLyGrouped.length; i++) {
            this.wasserlieferantenLy[i] = {};
            let val1 = wasserlieferantenLyGrouped[i].filter((el) =>
                el[0].endsWith('_2')
            );
            val1 = val1[0][1];
            if (typeof val1 == 'string') {
                this.wasserlieferantenLy[i]['value1'] = parseInt(
                    val1.replaceAll('.', '')
                ).toLocaleString('de-DE');
            } else {
                this.wasserlieferantenLy[i]['value1'] = val1.toLocaleString('de-DE');
            }
            let val2 = wasserlieferantenLyGrouped[i].filter((el) =>
                el[0].endsWith('_1')
            );
            val2 = val2[0][1];
            if (typeof val2 == 'string') {
                this.wasserlieferantenLy[i]['value2'] = parseInt(
                    val2.replaceAll('.', '')
                ).toLocaleString('de-DE');
            } else {
                this.wasserlieferantenLy[i]['value2'] = val2.toLocaleString('de-DE');
            }
            this.wasserlieferantenLy[i]['sum'] = (
                parseInt(this.wasserlieferantenLy[i].value1.replaceAll('.', '')) +
                parseInt(this.wasserlieferantenLy[i].value2.replaceAll('.', ''))
            ).toLocaleString('de-DE');
            let note = wasserlieferantenLyGrouped[i].filter((el) =>
                el[0].endsWith('_note')
            );
            if (note[0]) {
                this.wasserlieferantenLy[i]['note'] = note[0][1];
            } else {
                this.wasserlieferantenLy[i]['note'] = "";
            }
        }
        if (this.saveData.previousData) {
            let originVariantLyArray = Object.entries(this.saveData.previousData).filter(([key]) =>
                key.includes(originVariant)
            );
            for (let i = 0; i < originVariantLyArray.length; i++) {
                this.wasserlieferantenLy[i]['bezugsart'] = originVariantLyArray[i][1];
            }
        }

        const wasserlieferantenGrouped = this.createGroups(
            wasserlieferantenArray,
            4
        );
        for (let i = 0; i < wasserlieferantenGrouped.length; i++) {
            this.add(this.wasserlieferanten, true);
            let val1 = wasserlieferantenGrouped[i].filter((el) =>
                el[0].endsWith('_2')
            );
            val1 = val1[0][1];
            this.wasserlieferanten[i]['value1'] = parseInt(
                val1.replaceAll('.', '')
            ).toLocaleString('de-DE');
            let val2 = wasserlieferantenGrouped[i].filter((el) =>
                el[0].endsWith('_1')
            );
            val2 = val2[0][1];
            this.wasserlieferanten[i]['value2'] = parseInt(
                val2.replaceAll('.', '')
            ).toLocaleString('de-DE');
            this.wasserlieferanten[i]['sum'] = (
                parseInt(this.wasserlieferanten[i].value1.replaceAll('.', '')) +
                parseInt(this.wasserlieferanten[i].value2.replaceAll('.', ''))
            ).toLocaleString('de-DE');
            let note = wasserlieferantenGrouped[i].filter((el) =>
                el[0].endsWith('_note')
            );
            if (note[0]) {
                this.wasserlieferanten[i]['note'] = note[0][1];
            } else {
                this.wasserlieferanten[i]['note'] = "";
            }
            if (this.status !== 'bearbeitung' && this.saveData.previousData && this.wasserlieferanten[i] && this.wasserlieferantenLy[i]) {
                if (
                    parseInt(
                        this.wasserlieferanten[i].value1.replace(/[^\d]/g, '')
                    ).toLocaleString('de-DE') !==
                    parseInt(
                        this.wasserlieferantenLy[i]['value1'].replace(/[^\d]/g, '')
                    ).toLocaleString('de-DE') && (!(this.wasserlieferantenLy[i]['value1'] == 0 && this.status == 'pruefung'))
                ) {
                    this.wasserlieferanten[i]['value1Dif'] = 'dif';
                }
                if (
                    parseInt(
                        this.wasserlieferanten[i].value2.replace(/[^\d]/g, '')
                    ).toLocaleString('de-DE') !==
                    parseInt(
                        this.wasserlieferantenLy[i]['value2'].replace(/[^\d]/g, '')
                    ).toLocaleString('de-DE') && (!(this.wasserlieferantenLy[i]['value2'] == 0 && this.status == 'pruefung'))
                ) {
                    this.wasserlieferanten[i]['value2Dif'] = 'dif';
                }
            }
            const dbnum = this.formtype == 'OEFF' && ((i + 1) < 10) ? '0' + (i + 1) : (i + 1);
            if (this.saveData.comments) {
                const commentLabelObj = this.saveData.comments.find(
                    (e) => e.key == namepartOrigin + dbnum
                );
                commentLabelObj ? this.wasserlieferanten[i].labelComment = commentLabelObj.value : null;
                const comment1Obj = this.saveData.comments.find(
                    (e) => e.key == namepartVal + dbnum + '_2'
                );
                comment1Obj ? this.wasserlieferanten[i].value1Comment = comment1Obj.value : null;
                const comment2Obj = this.saveData.comments.find(
                    (e) => e.key == namepartVal + dbnum + '_1'
                );
                comment2Obj ? this.wasserlieferanten[i].value2Comment = comment2Obj.value : null;
            }
        }
        if (this.saveData) {
            let originVariantArray = Object.entries(this.saveData).filter(([key]) =>
                key.includes(originVariant)
            );
            for (let i = 0; i < originVariantArray.length; i++) {
                this.wasserlieferanten[i]['bezugsart'] = originVariantArray[i][1];
            }
        }

        const wasserlieferantenLabelsArray = Object.entries(this.saveData).filter(
            ([key]) => key.includes(namepartOrigin)
        );

        const nonEmptyWasserlieferantenLabels = wasserlieferantenLabelsArray;

        const wasserlieferantenLength = this.wasserlieferanten.length;
        for (
            let i = wasserlieferantenLength;
            i < nonEmptyWasserlieferantenLabels.length;
            i++
        ) {
            this.add(this.wasserlieferanten, true);
        }

        for (let i = 0; i < nonEmptyWasserlieferantenLabels.length; i++) {
            this.wasserlieferanten[i].label = nonEmptyWasserlieferantenLabels[i][1];
        }
    },
    getFremdabgabe(groupName, namepartVal, namepartOrigin, originVariant) {
        let wasserempfaengerArray = [];
        let wasserempfaengerArrayPrevious = [];
        const groupNameLy = groupName.includes('Pl') ? groupName.replace('Pl', '') + 'Ly' : groupName + 'Ly';

        if (this.saveData) {
            wasserempfaengerArray = Object.entries(this.saveData).filter(([key]) =>
                key.includes(namepartVal) && !key.includes(originVariant)
            );
        }

        if (this.status !== 'bearbeitung' && this.saveData.previousData) {
            wasserempfaengerArrayPrevious = Object.entries(
                this.saveData.previousData
            ).filter(([key]) => key.includes(namepartVal) && !key.includes(originVariant));
        }

        const wasserempfaengerLyGrouped = this.createGroups(
            wasserempfaengerArrayPrevious,
            4
        );
        for (let i = 0; i < wasserempfaengerLyGrouped.length; i++) {
            this[groupNameLy][i] = {};
            let val1 = wasserempfaengerLyGrouped[i].filter((el) =>
                el[0].endsWith('_2')
            );
            val1 = val1[0][1];
            this[groupNameLy][i]['value1'] = parseInt(
                val1.replaceAll('.', '')
            ).toLocaleString('de-DE');
            let val2 = wasserempfaengerLyGrouped[i].filter((el) =>
                el[0].endsWith('_1')
            );
            val2 = val2[0][1];
            this[groupNameLy][i]['value2'] = parseInt(
                val2.replaceAll('.', '')
            ).toLocaleString('de-DE');
            this[groupNameLy][i]['sum'] = (
                parseInt(this[groupNameLy][i].value1.replaceAll('.', '')) +
                parseInt(this[groupNameLy][i].value2.replaceAll('.', ''))
            ).toLocaleString('de-DE');
            let note = wasserempfaengerLyGrouped[i].filter((el) =>
                el[0].endsWith('_note')
            );
            if (note[0]) {
                this[groupNameLy][i]['note'] = note[0][1];
            } else {
                this[groupNameLy][i]['note'] = "";
            }
        }
        if (this.saveData.previousData) {
            let originVariantLyArray = Object.entries(this.saveData.previousData).filter(([key]) =>
                key.includes(originVariant)
            );
            for (let i = 0; i < originVariantLyArray.length; i++) {
                this[groupNameLy][i]['abgabeart'] = originVariantLyArray[i][1];
            }
        }

        const wasserempfaengerGrouped = this.createGroups(
            wasserempfaengerArray,
            4
        );
        for (let i = 0; i < wasserempfaengerGrouped.length; i++) {
            this.add(this[groupName], true);
            let val1 = wasserempfaengerGrouped[i].filter((el) =>
                el[0].endsWith('_2')
            );
            val1 = val1[0][1];
            this[groupName][i]['value1'] = parseInt(
                val1.replaceAll('.', '')
            ).toLocaleString('de-DE');
            let val2 = wasserempfaengerGrouped[i].filter((el) =>
                el[0].endsWith('_1')
            );
            val2 = val2[0][1];
            this[groupName][i]['value2'] = parseInt(
                val2.replaceAll('.', '')
            ).toLocaleString('de-DE');
            this[groupName][i]['sum'] = (
                parseInt(this[groupName][i].value1.replaceAll('.', '')) +
                parseInt(this[groupName][i].value2.replaceAll('.', ''))
            ).toLocaleString('de-DE');
            let note = wasserempfaengerGrouped[i].filter((el) =>
                el[0].endsWith('_note')
            );
            if (note[0]) {
                this[groupName][i]['note'] = note[0][1];
            } else {
                this[groupName][i]['note'] = "";
            }
            if (this.status !== 'bearbeitung' && this.saveData.previousData && this[groupName][i] && this[groupNameLy][i]) {
                if (
                    parseInt(
                        this[groupName][i]['value1'].replace(/[^\d]/g, '')
                    ).toLocaleString('de-DE') !==
                    parseInt(
                        this[groupNameLy][i]['value1'].replace(/[^\d]/g, '')
                    ).toLocaleString('de-DE') && (!(this[groupNameLy][i]['value1'] == 0 && this.status == 'pruefung'))
                ) {
                    this[groupName][i]['value1Dif'] = 'dif';
                }
                if (
                    parseInt(
                        this[groupName][i]['value2'].replace(/[^\d]/g, '')
                    ).toLocaleString('de-DE') !==
                    parseInt(
                        this[groupNameLy][i]['value2'].replace(/[^\d]/g, '')
                    ).toLocaleString('de-DE') && (!(this[groupNameLy][i]['value2'] == 0 && this.status == 'pruefung'))
                ) {
                    this[groupName][i]['value2Dif'] = 'dif';
                }
            }
            const dbnum = this.formtype == 'OEFF' && ((i + 1) < 10) ? '0' + (i + 1) : (i + 1);
            if (this.saveData.comments) {
                const commentLabelObj = this.saveData.comments.find(
                    (e) => e.key == namepartOrigin + dbnum
                );
                commentLabelObj ? this[groupName][i].labelComment = commentLabelObj.value : null;
                const comment1Obj = this.saveData.comments.find(
                    (e) => e.key == namepartVal + dbnum + '_2'
                );
                comment1Obj ? this[groupName][i].value1Comment = comment1Obj.value : null;
                const comment2Obj = this.saveData.comments.find(
                    (e) => e.key == namepartVal + dbnum + '_1'
                );
                comment2Obj ? this[groupName][i].value2Comment = comment2Obj.value : null;
            }
        }
        if (this.saveData) {
            let originVariantArray = Object.entries(this.saveData).filter(([key]) =>
                key.includes(originVariant)
            );
            for (let i = 0; i < originVariantArray.length; i++) {
                this[groupName][i]['abgabeart'] = originVariantArray[i][1];
            }
        }

        const wasserempfaengerLabelsArray = Object.entries(this.saveData).filter(
            ([key]) => key.includes(namepartOrigin)
        );

        const nonEmptywasserempfaengerLabels = wasserempfaengerLabelsArray;

        const wasserempfaengerLength = this[groupName].length;
        for (
            let i = wasserempfaengerLength;
            i < nonEmptywasserempfaengerLabels.length;
            i++
        ) {
            this.add(this[groupName], true);
        }

        for (let i = 0; i < nonEmptywasserempfaengerLabels.length; i++) {
            this[groupName][i].label = nonEmptywasserempfaengerLabels[i][1];
        }
    },
    getSingleRowValues(keypart) {
        let second = keypart + '_2';
        let first = keypart + '_1';
        let total = keypart + '_G';
        if (this.saveData.previousData) {
            this[second + '_ly'] = parseInt(
                this.saveData.previousData[second]
            ).toLocaleString('de-DE');
            this[first + '_ly'] = parseInt(
                this.saveData.previousData[first]
            ).toLocaleString('de-DE');
            if (keypart === 'sonstigeNutzung' || keypart === 'sonstigeBeseitigung') {
                this[total + '_ly'] = (parseInt(this.saveData.previousData[second]) + parseInt(this.saveData.previousData[first])).toLocaleString('de-DE');
            } else {
                this[total + '_ly'] = parseInt(
                    this.saveData.previousData[total]
                ).toLocaleString('de-DE');
            }
        }
    },
    getSingleRowSaveData(groupName, keypart) {
        const obj = {};
        const key1 = keypart + '_2';
        const key2 = keypart + '_1';
        const key3 = keypart + '_G';
        const key4 = keypart + '_note';
        if (typeof this[groupName].inputs[key1] === 'string') {
            obj[key1] = parseInt(this[groupName].inputs[key1].replaceAll('.', ''));
        } else if (typeof this[groupName].inputs[key1] === 'number') {
            obj[key1] = this[groupName].inputs[key1];
        }
        if (typeof this[groupName].inputs[key2] === 'string') {
            obj[key2] = parseInt(this[groupName].inputs[key2].replaceAll('.', ''));
        } else if (typeof this[groupName].inputs[key2] === 'number') {
            obj[key2] = this[groupName].inputs[key2];
        }
        if (
            (!(keypart === 'sonstigeNutzung' || keypart === 'sonstigeBeseitigung')) && keypart !== 'schwimm'
        ) {
            obj[key3] = parseInt(this[key3].replaceAll('.', ''));
        }
        obj[key4] = this[groupName].note;
        return obj;
    },
    async setStatusesOnLoad() {
        if (this.saveData.status === 'ausstehend' || this.saveData.status === '') {
            await this.$store.dispatch('setStatusPending');
        }
        else if (this.saveData.status === 'bearbeitung') {
            await this.$store.dispatch('setStatusBearbeitung');
        } else if (this.saveData.status === 'pruefung') {
            await this.$store.dispatch('setStatusPruefung');
            if (this.usertype !== 1) {
                this.$store.dispatch('setPruefungAlert', true);
            }
        } else if (this.saveData.status === 'korrektur') {
            await this.$store.dispatch('setStatusKorrektur');
        } else if (this.saveData.status === 'erste_freigabe') {
            await this.$store.dispatch('setStatusErsteFreigabe');
        } else if (this.saveData.status === 'freigegeben') {
            await this.$store.dispatch('setStatusFreigegeben');
        }
        if (this.saveData.status === 'erste_freigabe' || this.saveData.status === 'freigegeben')
            if (this.usertype !== 1) {
                this.$store.dispatch('setApprovedAlert', true);
                const date = this.saveData.datum_korrektur.replaceAll(/(\d{4})-(\d{2})-(\d{2}).*/g, '$3.$2.$1');
                this.$store.dispatch('setDateOfFormSubmission', date);
            }
    },
    async progressState() {
        if (
            this.saveData.status === 'bearbeitung' ||
            this.saveData.status === 'ausstehend' ||
            this.saveData.status === ''
        ) {
            await this.$store.dispatch('setStatusPruefung');
        } else if (this.saveData.status === 'pruefung') {
            await this.$store.dispatch('setStatusKorrektur');
        } else if (this.saveData.status === 'korrektur') {
            await this.$store.dispatch('setStatusPruefung');
        } else if (this.saveData.status === 'erste_freigabe') {
            await this.$store.dispatch('setStatusPruefung');
        }
    },
    generateId(prefix, length) {
        const idLength = length - prefix.length;
        const idGenerator = new ShortUniqueId({ length: idLength });
        const generatedId = idGenerator();
        return prefix + generatedId;
    },
    async sendWStatusPruefung() {
        this.$store.dispatch('setStatusPruefung');
        const params = new URLSearchParams({
            formtype: this.formtype,
            user: this.user,
            jahr: this.year,
        }).toString();
        const url = '/API-DB/formData?' + params;
        let { saveData } = this.getSaveData();
        console.log('saveData: ', saveData);
        const stringifiedSaveData = JSON.stringify(saveData);
        this.$store.dispatch('setLoadingTrue');

        try {
            let response = await axios.post(url, stringifiedSaveData, {
                headers: { 'Content-Type': 'application/json' },
            });
            console.log('response: ', response);
            this.$store.dispatch('setAlertShow', true);
            this.$store.dispatch('setAlertMsg', 'Formular gespeichert');
            this.$store.dispatch('setAlertType', 'info');
            setTimeout(() => {
                this.$store.commit('setAlertShow', false);
            }, 3 * 1000);
            this.$store.dispatch('setLoadingFalse');
            this.toDashboard(this.usertype);
        } catch (error) {
            console.log('error: ', error);
            this.$store.dispatch('setAlertShow', true);
            this.$store.dispatch('setAlertMsg', 'Formular konnte nicht gespeichert werden');
            this.$store.dispatch('setAlertType', 'error');
            setTimeout(() => {
                this.$store.commit('setAlertShow', false);
            }, 5 * 1000);
            this.$store.dispatch('setLoadingFalse');
        }
    },
    getStampData() {
        const stampDataObj = {};
        if (this.saveData.weiterhin_mitglied) {
            stampDataObj['weiterhin_mitglied'] = (this.saveData.weiterhin_mitglied === '0' || this.saveData.weiterhin_mitglied === 0) ? false : (this.saveData.weiterhin_mitglied === '1' || this.saveData.weiterhin_mitglied === 1) ? true : null;
        } else {
            stampDataObj['weiterhin_mitglied'] = false;
        }
        if (this.saveData.wird_zum_mitglied) {
            stampDataObj['wird_zum_mitglied'] = (this.saveData.wird_zum_mitglied === '0' || this.saveData.wird_zum_mitglied === 0) ? false : (this.saveData.wird_zum_mitglied === '1' || this.saveData.wird_zum_mitglied === 1) ? true : null;
        } else {
            stampDataObj['wird_zum_mitglied'] = false;
        }
        if (this.saveData.weiterhin_nichtmitglied) {
            stampDataObj['weiterhin_nichtmitglied'] = (this.saveData.weiterhin_nichtmitglied === '0' || this.saveData.weiterhin_nichtmitglied === 0) ? false : (this.saveData.weiterhin_nichtmitglied === '1' || this.saveData.weiterhin_nichtmitglied === 1) ? true : null;
        } else {
            stampDataObj['weiterhin_nichtmitglied'] = false;
        }
        if (this.saveData.wird_zum_nichtmitglied) {
            stampDataObj['wird_zum_nichtmitglied'] = (this.saveData.wird_zum_nichtmitglied === '0' || this.saveData.wird_zum_nichtmitglied === 0) ? false : (this.saveData.wird_zum_nichtmitglied === '1' || this.saveData.wird_zum_nichtmitglied === 1) ? true : null;
        } else {
            stampDataObj['wird_zum_nichtmitglied'] = false;
        }
        if (this.saveData.informationsschreiben) {
            stampDataObj['informationsschreiben'] = (this.saveData.informationsschreiben === '0' || this.saveData.informationsschreiben === 0) ? false : (this.saveData.informationsschreiben === '1' || this.saveData.informationsschreiben === 1) ? true : null;
        } else {
            stampDataObj['informationsschreiben'] = false;
        }
        stampDataObj['veranlagte_wassermenge'] = this.saveData.veranlagte_wassermenge ? this.saveData.veranlagte_wassermenge : '';
        stampDataObj['abgesetzte_wassermenge'] = this.saveData.abgesetzte_wassermenge ? this.saveData.abgesetzte_wassermenge : '';
        if (this.saveData.hydrologie) {
            typeof this.saveData.hydrologie === 'string' ? stampDataObj['hydrologie'] = this.saveData.hydrologie : stampDataObj['hydrologie'] = this.saveData.hydrologie.toString();
        } else {
            stampDataObj['hydrologie'] = '';
        }
        if (this.saveData.wasserversorgung) {
            typeof this.saveData.wasserversorgung === 'string' ? stampDataObj['wasserversorgung'] = this.saveData.wasserversorgung : stampDataObj['wasserversorgung'] = this.saveData.wasserversorgung.toString();
        } else {
            stampDataObj['wasserversorgung'] = '';
        }
        if (this.saveData.menge) {
            typeof this.saveData.menge === 'string' ? stampDataObj['menge'] = this.saveData.menge : stampDataObj['menge'] = this.saveData.menge.toString();
        } else {
            stampDataObj['menge'] = '';
        }
        if (this.saveData.grund) {
            stampDataObj['grund'] = typeof this.saveData.grund === 'number' ? this.saveData.grund : parseInt(this.saveData.grund);
        } else {
            stampDataObj['grund'] = '';
        }
        stampDataObj['naechste_befragung'] = this.saveData.naechste_befragung ? this.saveData.naechste_befragung : '';
        return stampDataObj;
    },
    getStampDataToSend() {
        const stampDataObj = this.stampData;
        const textFields = ['veranlagte_wassermenge', 'abgesetzte_wassermenge', 'hydrologie', 'wasserversorgung', 'menge', 'grund', 'naechste_befragung'];
        Object.keys(stampDataObj).forEach((key) => {
            if (!textFields.includes(key)) {
                stampDataObj[key] === true || stampDataObj[key] === '1' ? stampDataObj[key] = '1' : stampDataObj[key] = '0';
            } else if (key === 'naechste_befragung' || key === 'veranlagte_wassermenge' || key === 'abgesetzte_wassermenge') {
                return;
            }
            else if (typeof stampDataObj[key] !== 'number') {
                stampDataObj[key] = parseInt(stampDataObj[key].replaceAll('.', ''));
            }
        });
        return stampDataObj;
    },
    savePreviousId(e, ref) {
        ref.previousFOENR_GES = e.target.value;
    },
    async sendIdUpdateRequest(e, ref) {
        if (ref.addedByUser === false) {
            console.log('not added by user -> no request sent');
            return;
        }
        console.log('previousId: ', ref.previousFOENR_GES);
        console.log('newId: ', e.target.value);
        if (ref.previousFOENR_GES !== e.target.value) {
            try {
                await axios.get('/API-DB/changeTmpNumber', {
                    params: { tmp_foenr_ges: ref.previousFOENR_GES, foenr_ges: e.target.value }
                });
                if (this.gewinnungsanlagen.includes(ref)) {
                    const matchInPrevData = this.newPreviousData.gewinnungsanlagen.find((el) => el.FOENR_GES == ref.previousFOENR_GES);
                    if (matchInPrevData) {
                        matchInPrevData.FOENR_GES = e.target.value;
                    }
                } else if (this.gewinnungsanlagenOberflaeche) {
                    if (this.gewinnungsanlagenOberflaeche.includes(ref)) {
                        const matchInPrevData = this.newPreviousData.gewinnungsanlagenOberflaeche.find((el) => el.FOENR_GES == ref.previousFOENR_GES);
                        matchInPrevData.FOENR_GES = e.target.value;
                    }
                } else if (this.wasserlaeufe) {
                    if (this.wasserlaeufe.includes(ref)) {
                        const matchInPrevData = this.newPreviousData.gewinnungsanlagenOberflaeche.find((el) => el.FOENR_GES == ref.previousFOENR_GES);
                        matchInPrevData.FOENR_GES = e.target.value;
                    }
                }
            } catch (err) {
                console.log('Error: Unable to update ID', err);
            }
        }
    },
    async deleteRow(FOENR_GES, type) {
        const mimitgl = this.$route.params.username;
        await axios.get('/API-DB/deleteZeileEigengewinnung', {
            params: {
                foemitgl: mimitgl,
                foenr_ges: FOENR_GES,
                type: type,
            }
        });
    },
    handleTabKey(sections) {
        if (this.tabController) {
            this.removeTabListener();
        }
        const tabs = [];
        for (let i = 0; i < sections.length; i++) {
            const sectionNodes = sections[i].querySelectorAll('[data-tabindex]');
            const sectionTabs = [];
            for (let i = 0; i < sectionNodes.length; i++) {
                if (sectionNodes[i].dataset.tabindex !== null) {
                    sectionTabs.push(sectionNodes[i]);
                };
            }
            // sort by data-tabindex
            const sortedSectionTabs = sectionTabs.sort((a, b) => {
                const tabIndexA = a.dataset.tabindex;
                const tabIndexB = b.dataset.tabindex;
                if (tabIndexA < tabIndexB) {
                    return -1;
                }
                if (tabIndexA > tabIndexB) {
                    return 1;
                }
                return 0;
            });
            tabs.push(...sortedSectionTabs);
        }
        const controller = new AbortController();
        this.tabController = controller;
        document.addEventListener("keydown", (e) => { this.detectTabKey(e, tabs); }, { signal: controller.signal });
    },
    detectTabKey(e, tabs) {
        // if (e.key == 'Tab' || (e.key == 'Enter' && e.target.tagName != 'BUTTON')) { // open buttons/icons with enter, otherwise target next field
        if (e.key == 'Tab' || e.key == 'Enter') { // also continue with enter key if current element is button
            e.preventDefault();
            e.stopPropagation();
            const activeEl = document.activeElement;
            if (!activeEl) {
                tabs[0].focus();
            } else {
                const currentIndex = tabs.findIndex((x) => x == activeEl);
                console.log('currentIndex: ', currentIndex);
                if (tabs[currentIndex + 1]) {
                    tabs[currentIndex + 1].focus();
                } else if (tabs[currentIndex] == tabs[tabs.length - 1]) {
                    tabs[0].focus();
                }
            }
        }
    },
    removeTabListener() {
        this.tabController.abort();
    },
    observeDOMMutations() {
        // Select the node that will be observed for mutations
        const targetNode = this.$refs.form.$el;

        // Options for the observer (which mutations to observe)
        const config = {
            childList: true,
            attributes: false,
            // Omit (or set to false) to observe only changes to the parent node
            subtree: true,
        };

        // Callback function to execute when mutations are observed
        const callback = (mutationList, observer) => {
            for (const mutation of mutationList) {
                if (mutation.type === "childList") {
                    console.log("A child node has been added or removed.");
                    const tabSections = document.querySelectorAll('[data-section="row"]');
                    this.handleTabKey(tabSections);
                }
            }
        };

        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);
        this.DOMObserver = observer;

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
        console.log('started observing DOM');
    },
    assemblePreviousData(data, gewinnungsanlagenOberflaecheName, fremdbezugGroupName, fremdbezugMaxLength, fremdbezugNamePart, fremdbezugLabelName, bezugsartNamePart, fremdabgabeGroupName, fremdabgabeMaxLength, fremdabgabeNamePart, fremdabgabeLabelName, abgabeartNamePart) {
        data['previousData'] = JSON.parse(JSON.stringify(this.newPreviousData));
        delete data.previousData.previousData;
        // remove parts of initial data
        delete data.previousData.comments;
        data.previousData.comments = [];
        if (this.newPreviousData['comments']) {
            for (let i = 0; i < this.newPreviousData['comments'].length; i++) {
                const el = this.newPreviousData['comments'][i];
                if (!(el.key.includes(fremdbezugNamePart) || el.key.includes(fremdabgabeNamePart) || el.key.includes(fremdbezugLabelName) || el.key.includes(fremdabgabeLabelName) || el.key.includes('EIGEN'))) {
                    data.previousData.comments.push(el);
                }
            }
        }
        delete data['previousData']['gewinnungsanlagen'];
        delete data['previousData'][gewinnungsanlagenOberflaecheName];
        delete data['previousData'][fremdbezugGroupName];
        delete data['previousData'][fremdabgabeGroupName];
        // add modified data from form
        // gewinnungsanlagen
        let gewinnungsanlagenSaveData = [];
        for (let i = 0; i < this.newPreviousData['gewinnungsanlagen'].length; i++) {
            const label = 'OSNAME';
            const val1 = 'FOEMENG_2';
            const val2 = 'FOEMENG_1';
            const total = 'FOEMENG_G';
            gewinnungsanlagenSaveData[i] = {};
            gewinnungsanlagenSaveData[i][label] = this.newPreviousData['gewinnungsanlagen'][i]['label'] ? this.newPreviousData['gewinnungsanlagen'][i]['label'] : '';
            gewinnungsanlagenSaveData[i][val1] = parseInt(
                this.newPreviousData['gewinnungsanlagen'][i]['value1'].replaceAll('.', '')
            );
            gewinnungsanlagenSaveData[i][val2] = parseInt(
                this.newPreviousData['gewinnungsanlagen'][i]['value2'].replaceAll('.', '')
            );
            gewinnungsanlagenSaveData[i][total] = parseInt(
                this.newPreviousData['gewinnungsanlagen'][i]['sum'].replaceAll('.', '')
            );
            gewinnungsanlagenSaveData[i]['OSRECHT_A'] =
                this.newPreviousData['gewinnungsanlagen'][i]['OSRECHT_A'] ? this.newPreviousData['gewinnungsanlagen'][i]['OSRECHT_A'] : '0';
            gewinnungsanlagenSaveData[i]['OSHORIZONT'] =
                this.newPreviousData['gewinnungsanlagen'][i]['OSHORIZONT'] ? this.newPreviousData['gewinnungsanlagen'][i]['OSHORIZONT'] : '';
            gewinnungsanlagenSaveData[i]['OSSTOCKWERK'] =
                this.newPreviousData['gewinnungsanlagen'][i]['OSSTOCKWERK'] ? this.newPreviousData['gewinnungsanlagen'][i]['OSSTOCKWERK'] : '';
            gewinnungsanlagenSaveData[i]['note'] = this.newPreviousData['gewinnungsanlagen'][i]['note'] ? this.newPreviousData['gewinnungsanlagen'][i]['note'] : '';
            gewinnungsanlagenSaveData[i]['FOENR_GES'] = this.newPreviousData['gewinnungsanlagen'][i]['FOENR_GES'];
            // comments
            if (this.newPreviousData['gewinnungsanlagen'][i].labelComment) {
                data.previousData.comments.push({
                    key: 'EIGEN_' + i + '_' + label,
                    value: this.newPreviousData['gewinnungsanlagen'][i].labelComment,
                });
            }
            if (this.newPreviousData['gewinnungsanlagen'][i].value1Comment) {
                data.previousData.comments.push({
                    key: 'EIGEN_' + i + '_' + val1,
                    value: this.newPreviousData['gewinnungsanlagen'][i].value1Comment,
                });
            }
            if (this.newPreviousData['gewinnungsanlagen'][i].value2Comment) {
                data.previousData.comments.push({
                    key: 'EIGEN_' + i + '_' + val2,
                    value: this.newPreviousData['gewinnungsanlagen'][i].value2Comment,
                });
            }
        }
        data['previousData']['eigengewinnung'] = gewinnungsanlagenSaveData;

        // gewinnungsanlagen Oberfläche
        let gewinnungsanlagenOberflaecheSaveData = [];
        for (let i = 0; i < this.newPreviousData[gewinnungsanlagenOberflaecheName].length; i++) {
            const label = 'OSNAME';
            const val1 = 'FOEMENG_2';
            const val2 = 'FOEMENG_1';
            const total = 'FOEMENG_G';
            gewinnungsanlagenOberflaecheSaveData[i] = {};
            gewinnungsanlagenOberflaecheSaveData[i][label] = this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['label'] ? this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['label'] : '';
            gewinnungsanlagenOberflaecheSaveData[i][val1] = parseInt(
                this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['value1'].replaceAll('.', '')
            );
            gewinnungsanlagenOberflaecheSaveData[i][val2] = parseInt(
                this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['value2'].replaceAll('.', '')
            );
            gewinnungsanlagenOberflaecheSaveData[i][total] = parseInt(
                this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['sum'].replaceAll('.', '')
            );
            gewinnungsanlagenOberflaecheSaveData[i]['OSRECHT_A'] =
                this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['OSRECHT_A'] ? this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['OSRECHT_A'] : '0';
            gewinnungsanlagenOberflaecheSaveData[i]['OSHORIZONT'] =
                this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['OSHORIZONT'] ? this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['OSHORIZONT'] : '';
            gewinnungsanlagenOberflaecheSaveData[i]['OSSTOCKWERK'] =
                this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['OSSTOCKWERK'] ? this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['OSSTOCKWERK'] : '';
            gewinnungsanlagenOberflaecheSaveData[i]['note'] = this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['note'] ? this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['note'] : '';
            gewinnungsanlagenOberflaecheSaveData[i]['FOENR_GES'] = this.newPreviousData[gewinnungsanlagenOberflaecheName][i]['FOENR_GES'];
            // comments
            if (this.newPreviousData[gewinnungsanlagenOberflaecheName][i].labelComment) {
                data.previousData.comments.push({
                    key: 'EIGEN_OBERFLAECHE' + i + '_' + label,
                    value: this.newPreviousData[gewinnungsanlagenOberflaecheName][i].labelComment,
                });
            }
            if (this.newPreviousData[gewinnungsanlagenOberflaecheName][i].value1Comment) {
                data.previousData.comments.push({
                    key: 'EIGEN_OBERFLAECHE' + i + '_' + val1,
                    value: this.newPreviousData[gewinnungsanlagenOberflaecheName][i].value1Comment,
                });
            }
            if (this.newPreviousData[gewinnungsanlagenOberflaecheName][i].value2Comment) {
                data.previousData.comments.push({
                    key: 'EIGEN_OBERFLAECHE' + i + '_' + val2,
                    value: this.newPreviousData[gewinnungsanlagenOberflaecheName][i].value2Comment,
                });
            }
        }
        data['previousData']['eigengewinnungOberflaeche'] = gewinnungsanlagenOberflaecheSaveData;

        // fremdbezug
        while (this.newPreviousData[fremdbezugGroupName].length < fremdbezugMaxLength) {
            this.add(this.newPreviousData[fremdbezugGroupName], true);
        }
        let fremdbezugSaveData = [];
        for (let i = 0; i < this.newPreviousData[fremdbezugGroupName].length; i++) {
            let dbnum = i + 1;
            this.formtype == 'OEFF' && dbnum < 10 ? dbnum = '0' + dbnum : null;
            const label = fremdbezugLabelName + dbnum;
            const val1 = fremdbezugNamePart + dbnum + '_2';
            const val2 = fremdbezugNamePart + dbnum + '_1';
            const total = fremdbezugNamePart + dbnum + '_G';
            const note = fremdbezugNamePart + dbnum + '_note';
            const bezugsart = bezugsartNamePart + dbnum;
            fremdbezugSaveData[i] = {};
            fremdbezugSaveData[i][label] = this.newPreviousData[fremdbezugGroupName][i]['label'] ? this.newPreviousData[fremdbezugGroupName][i]['label'] : '';
            fremdbezugSaveData[i][val1] = parseInt(
                this.newPreviousData[fremdbezugGroupName][i]['value1'].replaceAll('.', '')
            ).toLocaleString('de-DE');
            fremdbezugSaveData[i][val2] = parseInt(
                this.newPreviousData[fremdbezugGroupName][i]['value2'].replaceAll('.', '')
            ).toLocaleString('de-DE');
            fremdbezugSaveData[i][total] = parseInt(
                this.newPreviousData[fremdbezugGroupName][i]['sum'].replaceAll('.', '')
            ).toLocaleString('de-DE');
            fremdbezugSaveData[i][bezugsart] =
                this.newPreviousData[fremdbezugGroupName][i]['bezugsart'] ? this.newPreviousData[fremdbezugGroupName][i]['bezugsart'] : '';
            fremdbezugSaveData[i][note] = this.newPreviousData[fremdbezugGroupName][i]['note'] ? this.newPreviousData[fremdbezugGroupName][i]['note'] : '';
            fremdbezugSaveData[i]['addedByUser'] = this.newPreviousData[fremdbezugGroupName][i]['addedByUser'] ? this.newPreviousData[fremdbezugGroupName][i]['addedByUser'] : null;

            // comments
            if (this.newPreviousData[fremdbezugGroupName][i].labelComment) {
                data.previousData.comments.push({
                    key: label,
                    value: this.newPreviousData[fremdbezugGroupName][i].labelComment,
                });
            }
            if (this.newPreviousData[fremdbezugGroupName][i].value1Comment) {
                data.previousData.comments.push({
                    key: val1,
                    value: this.newPreviousData[fremdbezugGroupName][i].value1Comment,
                });
            }
            if (this.newPreviousData[fremdbezugGroupName][i].value2Comment) {
                data.previousData.comments.push({
                    key: val2,
                    value: this.newPreviousData[fremdbezugGroupName][i].value2Comment,
                });
            }
        }
        fremdbezugSaveData.forEach((el) => {
            data['previousData'] = { ...data['previousData'], ...el };
        });

        // fremdabgabe
        if (fremdabgabeGroupName) {
            while (this.newPreviousData[fremdabgabeGroupName].length < fremdabgabeMaxLength) {
                this.add(this.newPreviousData[fremdabgabeGroupName], true);
            }
            let fremdabgabeSaveData = [];
            for (let i = 0; i < this.newPreviousData[fremdabgabeGroupName].length; i++) {
                let dbnum = i + 1;
                this.formtype == 'OEFF' && dbnum < 10 ? dbnum = '0' + dbnum : null;
                const label = fremdabgabeLabelName + dbnum;
                const val1 = fremdabgabeNamePart + dbnum + '_2';
                const val2 = fremdabgabeNamePart + dbnum + '_1';
                const total = fremdabgabeNamePart + dbnum + '_G';
                const note = fremdabgabeNamePart + dbnum + '_note';
                const bezugsart = bezugsartNamePart + dbnum;
                fremdabgabeSaveData[i] = {};
                fremdabgabeSaveData[i][label] = this.newPreviousData[fremdabgabeGroupName][i]['label'] ? this.newPreviousData[fremdabgabeGroupName][i]['label'] : '';
                fremdabgabeSaveData[i][val1] = parseInt(
                    this.newPreviousData[fremdabgabeGroupName][i]['value1'].replaceAll('.', '')
                ).toLocaleString('de-DE');
                fremdabgabeSaveData[i][val2] = parseInt(
                    this.newPreviousData[fremdabgabeGroupName][i]['value2'].replaceAll('.', '')
                ).toLocaleString('de-DE');
                fremdabgabeSaveData[i][total] = parseInt(
                    this.newPreviousData[fremdabgabeGroupName][i]['sum'].replaceAll('.', '')
                ).toLocaleString('de-DE');
                fremdabgabeSaveData[i][bezugsart] =
                    this.newPreviousData[fremdabgabeGroupName][i]['bezugsart'] ? this.newPreviousData[fremdabgabeGroupName][i]['bezugsart'] : '';
                fremdabgabeSaveData[i][note] = this.newPreviousData[fremdabgabeGroupName][i]['note'] ? this.newPreviousData[fremdabgabeGroupName][i]['note'] : '';
                fremdabgabeSaveData[i]['addedByUser'] = this.newPreviousData[fremdabgabeGroupName][i]['addedByUser'] ? this.newPreviousData[fremdabgabeGroupName][i]['addedByUser'] : null;

                // comments
                if (this.newPreviousData[fremdabgabeGroupName][i].labelComment) {
                    data.previousData.comments.push({
                        key: label,
                        value: this.newPreviousData[fremdabgabeGroupName][i].labelComment,
                    });
                }
                if (this.newPreviousData[fremdabgabeGroupName][i].value1Comment) {
                    data.previousData.comments.push({
                        key: val1,
                        value: this.newPreviousData[fremdabgabeGroupName][i].value1Comment,
                    });
                }
                if (this.newPreviousData[fremdabgabeGroupName][i].value2Comment) {
                    data.previousData.comments.push({
                        key: val2,
                        value: this.newPreviousData[fremdabgabeGroupName][i].value2Comment,
                    });
                }
            }
            fremdabgabeSaveData.forEach((el) => {
                data['previousData'] = { ...data['previousData'], ...el };
            });
        }
        return data;
    },
    buildNewPreviousData(fremdbezugGroupName, fremdbezugNamePart, fremdbezugOriginNamePart, bezugsartNamePart, fremdabgabeGroupName, fremdabgabeNamePart, fremdabgabeOriginNamePart, abgabeartNamePart) {
        this.newPreviousData = JSON.parse(JSON.stringify(this.saveData));
        console.log('this.newPreviousData: ', this.newPreviousData);
        Object.keys(this.newPreviousData).map((key) => {
            if (key.includes(fremdbezugNamePart) || key.includes(fremdabgabeNamePart) || key.includes(bezugsartNamePart) || key.includes(abgabeartNamePart) || key.includes(fremdbezugOriginNamePart) || key.includes(fremdabgabeOriginNamePart)) {
                delete this.newPreviousData[key];
            }
        });
        this.newPreviousData[fremdbezugGroupName] = JSON.parse(JSON.stringify(this[fremdbezugGroupName]));
        if (fremdabgabeGroupName) {
            this.newPreviousData[fremdabgabeGroupName] = JSON.parse(JSON.stringify(this[fremdabgabeGroupName]));
        }

        delete this.newPreviousData['eigengewinnung'];
        delete this.newPreviousData['eigengewinnungOberflaeche'];
        this.newPreviousData['gewinnungsanlagen'] = JSON.parse(JSON.stringify(this.gewinnungsanlagen));
        this.newPreviousData['gewinnungsanlagenOberflaeche'] = this.wasserlaeufe ? JSON.parse(JSON.stringify(this.wasserlaeufe)) : JSON.parse(JSON.stringify(this.gewinnungsanlagenOberflaeche));
    },
    entryIsNotEmpty(index, namePart) {
        let result = null;
        const name = namePart;
        if (namePart.includes('Pl')) {
            namePart = namePart.replace('Pl', '');
        }
        const nameLy = namePart + 'Ly';
        if (
            this[name][index].label === '' &&
            (this[name][index].value1 === 0 || this[name][index].value1 === '0') &&
            (this[name][index].value2 === 0 || this[name][index].value2 === '0')
        ) {
            if (this[nameLy][index]) {
                if (
                    (this[nameLy][index].value1 === 0 ||
                        this[nameLy][index].value1 === '0') &&
                    (this[nameLy][index].value2 === 0 ||
                        this[nameLy][index].value2 === '0')
                ) {
                    result = false;
                } else {
                    result = true;
                }
            } else {
                result = false;
            }
        } else {
            result = true;
        }
        return result;
    },
    async deleteEgRow(FOENR_GES, type, group, ref, groupLy) {
        await this.deleteRow(FOENR_GES, type);
        let groupName = '';
        type == 'EG'
            ? (groupName = 'gewinnungsanlagen')
            : type == 'EGO'
                ? (groupName = 'gewinnungsanlagenOberflaeche')
                : null;
        this.remove(group, ref, groupLy, groupName);
    },
    getSingleRowComments(group, namePart, label) {
        if (this.saveData.previousData && this.status !== 'bearbeitung') {
            const val1Name = namePart + '_2';
            const val2Name = namePart + '_1';
            const commentVal1 = this.saveData.comments.find((e) =>
                e.key.includes(val1Name)
            );
            const commentVal2 = this.saveData.comments.find((e) =>
                e.key.includes(val2Name)
            );
            this[group].value1Comment = commentVal1 ? commentVal1.value : '';
            this[group].value2Comment = commentVal2 ? commentVal2.value : '';
            if (label) {
                const labelName = namePart + '_label';
                const commentLabel = this.saveData.comments.find((e) =>
                    e.key.includes(labelName)
                );
                this[group].labelComment = commentLabel ? commentLabel.value : '';
            }
        }
    },
    determineSingleRowDif(group, namePart, label) {
        if (this.saveData.previousData && this.status !== 'bearbeitung') {
            const namePart1 = namePart + '_1';
            const namePart2 = namePart + '_2';
            const namePart1Ly = namePart1 + '_ly';
            const namePart2Ly = namePart2 + '_ly';
            const namePart1Dif = namePart1 + 'Dif';
            const namePart2Dif = namePart2 + 'Dif';
            if ((this[group].inputs[namePart2] !== this[namePart2Ly]) && (!(this[namePart2Ly] == 0 && this.status == 'pruefung'))) {
                this[group][namePart2Dif] = 'dif';
            }
            if ((this[group].inputs[namePart1] !== this[namePart1Ly]) && (!(this[namePart1Ly] == 0 && this.status == 'pruefung'))) {
                this[group][namePart1Dif] = 'dif';
            }
            if (label && this.saveData.previousData) {
                const namePartLabel = namePart + '_label';
                if (this[group][namePartLabel] && this.saveData.previousData[namePartLabel] && (this[group][namePartLabel] !== this.saveData.previousData[namePartLabel])) {
                    this[group].labelDif = 'dif';
                }
            }
        }
    },
    async changeInputReceived(item) {
        const formtype = item.category == 'Gewerbliche Betriebe' || item.category == 'Kies- und Betonwerke' || (item.category == 'Sport-/Freizeitanlagen' && item.mitglied.startsWith('4')) ? 'IND'
            : item.category == 'Öffentliche Wasserversorgung' ? 'OEFF'
                : item.category == 'Landwirtschaft' || (item.category == 'Sport-/Freizeitanlagen' && item.mitglied.startsWith('5')) ? 'LAND'
                    : '';
        const payload = { mimitgl: item.mitglied, jahr: item.year, formtype: formtype };
        const params = new URLSearchParams(payload).toString();
        const url = '/API-USER/setEingangskontrolle?' + params;
        const checkboxVal = item.inputReceived ? 1 : 0;
        let formData = new FormData();
        formData.append('eingangskontrolle', checkboxVal);
        console.log('url: ', url);
        for (const pair of formData.entries()) {
            console.log(`${pair[0]}: ${pair[1]}`);
        }
        try {
            let res = await axios.post(url, formData);
            console.log('res: ', res);
        } catch (err) {
            console.error('err: ', err);
        }
        console.log(item, "changed");
    },
    stopAutosaveAfterX(time) {
        setTimeout(() => {
            clearInterval(this.timer); // does nothing if interval has already been cleared
        }, time);
    },
    getInternalNotes() {
        if (this.saveData.hinweis_freigabe) {
            this.internalNotes.hinweiseFreigebenden = this.saveData.hinweis_freigabe;
        }
        if (this.saveData.hinweis_pruefung) {
            this.internalNotes.hinweisePruefenden = this.saveData.hinweis_pruefung;
        }
    }
};